import React, { useRef } from "react";
import { useFormik } from "formik";
import { reportTypeSchema } from "../../utils/schemas";
import {
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { MdAttachment } from "react-icons/md";

export default function ReportTypeModal({
  data,
  onUpdateStatus,
  statusOptions,
  isOpenReportType,
  onCloseReportType,
  onCloseStatus,
}) {
  const fileRef = useRef(null);
  const file2Ref = useRef(null);
  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      pdfFile: "",
      excelFile: "",
    },
    validationSchema: reportTypeSchema,
    onSubmit: (values) => {
      let files = [...data.orderFiles];
      files.push({ fileName: "pdfFile", fileURL: values.pdfFile });
      if (values.excelFile && values.excelFile !== "") {
        files.push({ fileName: "excelFile", fileURL: values.excelFile });
      }
      onUpdateStatus({ ...data, orderFiles: files }, statusOptions[2].value);
      onCloseReportType();
      onCloseStatus();
    },
  });
  return (
    <Modal
      isOpen={isOpenReportType}
      onClose={onCloseReportType}
      size="xl"
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Report</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack alignItems="start">
            <Text fontWeight="semibold">Select Result File</Text>
            <Stack
              flexDirection={{ base: "column", md: "row" }}
              flexWrap="wrap"
            >
              <Button
                bgColor="black"
                borderRadius="lg"
                fontWeight="medium"
                onClick={() => fileRef.current.click()}
              >
                Upload
              </Button>
              {formik.values.pdfFile !== "" && (
                <HStack borderWidth="1px" borderRadius="lg" p={2}>
                  <Icon as={MdAttachment} fontSize="xl" />
                  <Text>{formik.values.pdfFile.name}</Text>
                </HStack>
              )}
            </Stack>
            {formik.touched.pdfFile && formik.errors.pdfFile && (
              <Text color="red.500" fontSize="sm">
                {formik.errors.pdfFile}
              </Text>
            )}
            <input
              ref={fileRef}
              type="file"
              label="Select File"
              name="pdfFile"
              accept={"application/pdf"}
              onChange={(e) => {
                if (e.target.files[0].name.split(".").pop() === "pdf") {
                  formik.setFieldValue("pdfFile", e.target.files[0]);
                } else {
                  toast({
                    description: "Only PDF files allowed!",
                    status: "error",
                    isClosable: true,
                  });
                }
              }}
              style={{ display: "none" }}
            />
          </VStack>
          <VStack alignItems="start" mt={3}>
            <Text fontWeight="semibold">Select Additional File</Text>
            <Stack
              flexDirection={{ base: "column", md: "row" }}
              flexWrap="wrap"
            >
              <Button
                bgColor="black"
                borderRadius="lg"
                fontWeight="medium"
                onClick={() => file2Ref.current.click()}
              >
                Upload
              </Button>
              {formik.values.excelFile !== "" && (
                <HStack borderWidth="1px" borderRadius="lg" p={2}>
                  <Icon as={MdAttachment} fontSize="xl" />
                  <Text>{formik.values.excelFile.name}</Text>
                </HStack>
              )}
            </Stack>
            {formik.touched.excelFile && formik.errors.excelFile && (
              <Text color="red.500" fontSize="sm">
                {formik.errors.excelFile}
              </Text>
            )}
            <input
              ref={file2Ref}
              type="file"
              label="Select File"
              name="excelFile"
              accept={
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              }
              onChange={(e) => {
                if (
                  e.target.files[0].name.split(".").pop() === "xls" ||
                  e.target.files[0].name.split(".").pop() === "xlsx"
                ) {
                  formik.setFieldValue("excelFile", e.target.files[0]);
                } else {
                  toast({
                    description: "Only Excel files allowed!",
                    status: "error",
                    isClosable: true,
                  });
                }
              }}
              style={{ display: "none" }}
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            onClick={formik.handleSubmit}
            bgColor="black"
            borderRadius="lg"
            fontWeight="medium"
            py="6"
            px="7"
            me="2"
          >
            Submit
          </Button>
          <Button
            onClick={() => onCloseReportType()}
            bgColor="gray.200"
            color="black"
            borderRadius="lg"
            fontWeight="medium"
            py="6"
            px="7"
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
