import React, { useState } from "react";
import {
  Box,
  Center,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
  VStack,
  useSteps,
} from "@chakra-ui/react";
import { BusinessInfoStep1 } from "../components";
import { BusinessInfoStep2 } from "../components";
import { useNavigate } from "react-router-dom";

export default function BusinessInfo() {
  const [formData, setFormData] = useState();
  const navigate = useNavigate();

  const onNextStep = (stepData) => {
    let newData = { ...formData, ...stepData };
    console.log(newData);
    setFormData(newData);
    if (activeStep === 1) {
      onBusinessInfoSubmit();
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }
    setActiveStep(activeStep + 1);
  };

  const onBusinessInfoSubmit = () => {};

  const steps = [
    {
      title: "Business Info",
      description: "Provide the required information about your busienss.",
      content: <BusinessInfoStep1 onNextStep={onNextStep} />,
    },
    {
      title: "Payment Option",
      description: "Select a payment option for your account.",
      content: <BusinessInfoStep2 onNextStep={onNextStep} />,
    },
  ];
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  return (
    <>
      {activeStep < 2 ? (
        <>
          <Grid
            templateColumns="repeat(4, 1fr)"
            display={{ base: "none", md: "none", lg: "grid" }}
          >
            <GridItem colSpan={1} h="100vh" bgColor="primary.main" p="8">
              <Heading as="h2" color="white" size="md">
                Account Setup
              </Heading>
              <Text color="whiteAlpha.800" mb="4">
                Complete your account setup
              </Text>
              <Stepper
                index={activeStep}
                orientation="vertical"
                height="200px"
                gap="0"
              >
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepIndicator
                      sx={
                        index <= activeStep
                          ? {
                              background: "white !important",
                              color: "#08A6C9 !important",
                            }
                          : {
                              background: "rgba(255, 255, 255, 0.80)",
                              color: "white",
                            }
                      }
                    >
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box>
                      <StepTitle
                        color={index <= activeStep ? "white" : "whiteAlpha.800"}
                        fontWeight="bold"
                      >
                        {step.title}
                      </StepTitle>
                      <StepDescription color="whiteAlpha.800">
                        {step.description}
                      </StepDescription>
                    </Box>

                    <StepSeparator
                      sx={
                        index < activeStep
                          ? {
                              background: "white !important",
                            }
                          : {
                              background:
                                "rgba(255, 255, 255, 0.40) !important",
                            }
                      }
                    />
                  </Step>
                ))}
              </Stepper>
            </GridItem>
            <GridItem colSpan={3}>{steps[activeStep].content}</GridItem>
          </Grid>
          <Box display={{ base: "block", md: "block", lg: "none" }} p="8">
            <Heading as="h2" size="md">
              Account Setup
            </Heading>
            <Text mb="4">Complete your account setup</Text>
            <Center>
              <Stepper index={activeStep} w="150px">
                {steps.map((_, index) => (
                  <Step key={index}>
                    <StepIndicator
                      sx={
                        index <= activeStep
                          ? {
                              background: "#08A6C9 !important",
                              color: "white !important",
                            }
                          : {
                              background: "lightgray",
                              color: "white",
                            }
                      }
                    >
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <StepSeparator
                      sx={
                        index < activeStep
                          ? {
                              background: "#08A6C9 !important",
                            }
                          : {
                              background: "lightgray !important",
                            }
                      }
                    />
                  </Step>
                ))}
              </Stepper>
            </Center>
            <Box>{steps[activeStep].content}</Box>
          </Box>
        </>
      ) : (
        <Stack
          gap="2"
          p="4"
          h="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <Image
            src="/images/setup-complete-img.png"
            alt=""
            w={{ base: "80%", md: "50%", lg: "25%" }}
          />
          <Heading as="h2" textAlign="center">
            Account Setup Complete!
          </Heading>
          <Text fontSize="lg" textAlign="center">
            Your account is sent for approval now. Stay tuned!
          </Text>
        </Stack>
      )}
    </>
  );
}
