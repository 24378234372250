import React from "react";
import { PublicLayout } from "../layouts";
import { Button, HStack, Heading, Text, useToast } from "@chakra-ui/react";
import { CInput } from "../components";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { signupSchema } from "../utils/schemas";
import { AuthModel } from "../services/models";

export default function Signup() {
  const navigate = useNavigate();
  const toast = useToast();

  const onSignup = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await AuthModel.signup(values);
      toast({
        description: "Account created successfully!",
        status: "success",
        isClosable: true,
      });
      navigate("/login");
    } catch (error) {
      if (error.response.status === 409) {
        toast({
          description: error.response.data.message,
          status: "error",
          isClosable: true,
        });
      } else {
        toast({
          description: "Something went wrong!",
          status: "error",
          isClosable: true,
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: signupSchema,
    onSubmit: onSignup,
  });
  return (
    <PublicLayout>
      <Heading
        as="h1"
        w="full"
        textAlign={{ base: "center", lg: "start" }}
        mb="4"
      >
        Signup
      </Heading>
      <CInput
        label="First Name"
        type="text"
        name="firstName"
        placeholder="Enter First Name"
        value={formik.values.firstName}
        onChange={formik.handleChange}
        errors={formik.errors.firstName}
        touched={formik.touched.firstName}
      />
      <CInput
        label="Last Name"
        type="text"
        name="lastName"
        placeholder="Enter Last Name"
        value={formik.values.lastName}
        onChange={formik.handleChange}
        errors={formik.errors.lastName}
        touched={formik.touched.lastName}
      />
      <CInput
        label="Email"
        type="email"
        name="email"
        placeholder="Enter Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        errors={formik.errors.email}
        touched={formik.touched.email}
      />
      <CInput
        label="Password"
        type="password"
        name="password"
        placeholder="Enter Password"
        value={formik.values.password}
        onChange={formik.handleChange}
        errors={formik.errors.password}
        touched={formik.touched.password}
      />
      <CInput
        label="Confirm Password"
        type="password"
        name="confirmPassword"
        placeholder="Enter Password Again"
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        errors={formik.errors.confirmPassword}
        touched={formik.touched.confirmPassword}
      />
      <Button
        type="submit"
        onClick={formik.handleSubmit}
        isLoading={formik.isSubmitting}
        loadingText="Loading..."
        bgColor="black"
        w="full"
        borderRadius="lg"
        fontWeight="medium"
        py="6"
        my="2"
      >
        Signup
      </Button>
      <HStack>
        <Text>Already have an account?</Text>
        <Link
          to="/login"
          style={{
            fontWeight: "600",
            pointerEvents: formik.isSubmitting ? "none" : "auto",
          }}
        >
          Login
        </Link>
      </HStack>
    </PublicLayout>
  );
}
