import axios, { BASE_URL } from "../axios";
import BaseModel from "./baseModel";

export default class CocModel extends BaseModel {
  static endPoint = "cocs/";

  static getByUser(userId) {
    return axios.get(BASE_URL + this.endPoint + "?userId=" + userId);
  }

  static postToSlims(cocId){
    return axios.get(BASE_URL + this.endPoint + 'post-to-slims/'+ cocId);
  }
}
