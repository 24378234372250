import "@fontsource/poppins";
import { ChakraProvider, Box, Text, useMediaQuery, Image } from "@chakra-ui/react";
import { theme } from "./theme";
import { BrowserRouter, Routes } from "react-router-dom";
import { getRoutes } from "./routes";

function App() {
  const [isLargerThan1024] = useMediaQuery("(min-width: 1024px)");

  return (
    <ChakraProvider theme={theme}>
      {/* {isLargerThan1024 ? (
        <BrowserRouter>
          <Routes>{getRoutes()}</Routes>
        </BrowserRouter>
      ) : (
        <Box
          display="flex"
          flexDirection={'column'}
          justifyContent="center"
          alignItems="center"
          minH="100vh"
          textAlign="center"
          p={4}
        >
          <Box shadow={'2xl'} bgColor={'rgba(0,0,0,0.9)'} p={'10px'} borderRadius={'10px'} mb={'10px'}>
            <Image src="/images/logo.svg" alt="" borderRadius="md" width={'200px'} />
          </Box>
          <Text fontSize="sm">
            This app is not optimzied to use on mobile devices. Please use your laptop or desktop to use the app.
          </Text>
        </Box>
      )} */}

      <BrowserRouter>
        <Routes>{getRoutes()}</Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
