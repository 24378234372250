import React, { useState } from "react";
import { PublicLayout } from "../layouts";
import { GetEmail, ResetPassword, VerifyOTP } from "../components";

export default function ForgotPassword() {
  const [step, setStep] = useState(0);
  const [requestData, setRequestData] = useState({});
  const moveToNextStep = (values) => {
    setRequestData(values);
    setStep(step + 1);
  };
  const steps = [
    <GetEmail moveToNextStep={moveToNextStep} />,
    <VerifyOTP moveToNextStep={moveToNextStep} data={requestData} />,
    <ResetPassword data={requestData} />,
  ];

  return <PublicLayout>{steps[step]}</PublicLayout>;
}
