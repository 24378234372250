import React from "react";
import { AuthModel } from "../../services/models";
import { Navigate } from "react-router-dom";

export default function GuestRoute({ children }) {
  const isToken = AuthModel.getToken();
  const user = AuthModel.getUser();

  if (isToken) {
    return user.role === "admin" ? (
      <Navigate to="/" />
    ) : (
      <Navigate to="/all-cocs" />
    );
  } else {
    return children;
  }
}
