import React from "react";
import { Box, Icon, Link, Stack, Text, VStack } from "@chakra-ui/react";
import { GoHomeFill } from "react-icons/go";
import { BiSolidPlusSquare, BiSolidFile } from "react-icons/bi";
import { AiOutlinePoweroff } from "react-icons/ai";
import { MdAutoDelete, MdSettings } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthModel } from "../../services/models";

export default function Navbar() {
  const user = AuthModel.getUser();
  const navigate = useNavigate();

  const handleLogout = () => {
    AuthModel.logout();
    navigate("/login");
  };

  const navMenu =
    user.role === "admin"
      ? [
          {
            link: "/",
            icon: GoHomeFill,
            title: "Home",
          },
          {
            link: "/all-orders",
            icon: BiSolidFile,
            title: "All Orders",
          },
          // {
          //   link: "/coc-delete-requests",
          //   icon: MdAutoDelete,
          //   title: "All Requests",
          // },
          {
            link: "/configuration",
            icon: MdSettings,
            title: "Settings",
          },
          {
            icon: AiOutlinePoweroff,
            title: "Logout",
            onClick: () => handleLogout(),
          },
        ]
      : [
          {
            link: "/all-orders",
            icon: BiSolidFile,
            title: "All Orders",
          },
          {
            link: "/new-order",
            icon: BiSolidPlusSquare,
            title: "New Order",
          },
          {
            icon: AiOutlinePoweroff,
            title: "Logout",
            onClick: () => handleLogout(),
          },
        ];
  return (
    <Stack
      w={{ base: "full", lg: "120px" }}
      h={{ base: "auto", lg: "full" }}
      flexDirection={{ base: "row", lg: "column" }}
      justifyContent="center"
      alignItems="start"
      position={{ base: "fixed", lg: "relative" }}
      bottom={{ base: 0, lg: "auto" }}
      left={{ base: 0, lg: "auto" }}
      right={{ base: 0, lg: "auto" }}
      zIndex={99}
    >
      <Stack
        flexDirection={{ base: "row", lg: "column" }}
        w={{ base: "full", lg: "auto" }}
        justifyContent={{
          base: "space-evenly",
          md: "space-evenly",
          lg: "center",
        }}
        bgColor="white"
        shadow={{ base: "0px 0px 6px 0px rgb(0,0,0,0.1)", lg: "md" }}
        roundedTopLeft={{ base: "3xl", lg: "0" }}
        roundedTopRight={"3xl"}
        roundedBottomRight={{ md: "0", lg: "3xl" }}
        py="4"
        px="3"
        gap={{ md: "3", lg: "2" }}
      >
        {navMenu.map((navItem, i) => {
          if (navItem.onClick) {
            return (
              <VStack
                key={i}
                onClick={navItem.onClick}
                gap="0"
                cursor="pointer"
                role="group"
              >
                <Box
                  bgColor="red.50"
                  rounded="full"
                  w="fit-content"
                  px="2"
                  pt="2"
                  pb="0.5"
                  _groupHover={{ backgroundColor: "red" }}
                >
                  <Icon
                    as={navItem.icon}
                    color="red"
                    fontSize="3xl"
                    _groupHover={{ color: "white" }}
                  />
                </Box>
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  color="gray"
                  _groupHover={{ color: "red" }}
                >
                  {navItem.title}
                </Text>
              </VStack>
            );
          } else {
            return (
              <Link
                key={i}
                as={NavLink}
                to={navItem.link}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textDecoration: "none",
                }}
                role="group"
                _activeLink={{
                  "& > div": { backgroundColor: "primary.main" },
                  "& > div svg": { color: "white" },
                  "& p": { color: "primary.main" },
                }}
              >
                <Box
                  bgColor="primary.100"
                  rounded="full"
                  w="fit-content"
                  px="2"
                  pt="2"
                  pb="0.5"
                  _groupHover={{ backgroundColor: "primary.main" }}
                >
                  <Icon
                    as={navItem.icon}
                    color="primary.main"
                    fontSize="3xl"
                    _groupHover={{ color: "white" }}
                  />
                </Box>
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  color="gray"
                  _groupHover={{
                    color: "primary.main",
                  }}
                >
                  {navItem.title}
                </Text>
              </Link>
            );
          }
        })}
      </Stack>
    </Stack>
  );
}
