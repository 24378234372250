import React from "react";
import {
  Button,
  Center,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FiArrowLeftCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

export default function ApprovalPending() {
  const navigate = useNavigate();
  return (
    <SimpleGrid columns={{ md: "1", lg: "2" }}>
      <VStack
        h={{ md: "auto", lg: "100vh" }}
        p={["10", "14"]}
        justifyContent="center"
      >
        <VStack alignItems="start" gap="4">
          <Heading as="h2">Approval Pending</Heading>
          <Text fontSize="xl">
            Your account is under review right now. You will be notified after
            it has been reviewed as soon as possible.
          </Text>
          <Button
            leftIcon={<FiArrowLeftCircle color="#08A6C9" size="24px" />}
            color="black"
            pl="0"
            onClick={() => navigate("/login")}
          >
            Back To Login
          </Button>
        </VStack>
      </VStack>
      <Center>
        <Image src="/images/approval-pending-img.png" alt="" boxSize="70%" />
      </Center>
    </SimpleGrid>
  );
}
