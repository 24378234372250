import React from "react";
import { ProtectedLayout } from "../layouts";
import {
  Badge,
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { FaArrowPointer, FaWifi } from "react-icons/fa6";

export default function UserManual() {
  return (
    <ProtectedLayout>
      <HStack
        justifyContent="space-between"
        alignItems={{ base: "start", md: "center" }}
        flexDirection={{ base: "column", md: "row" }}
        mb="2"
      >
        <Heading fontSize={{ base: "xl", md: "3xl" }}>User Manual</Heading>
        <HStack w={{ base: "full", md: "220px" }}>
          <Icon as={FaWifi} fontSize="2xl" color="primary.main" />
          <Text fontSize="xs" fontWeight="semibold">
            Please ensure you have an active Internet Connection
          </Text>
        </HStack>
      </HStack>
      <Text mb={2}>
        This 10-Step User Manual will allow you to collect and track your NIOSH
        9111 Samples for laboratory analysis.
      </Text>
      <Text fontSize="sm" gap={1} mb={4}>
        If you are having issues, please call{" "}
        <Link
          href="tel:1300 383 871"
          fontWeight="semibold"
          color="primary.main"
        >
          1300 383 871
        </Link>{" "}
        or e-mail{" "}
        <Link
          href="mailto:itdesk@restechlabs.com.au"
          fontWeight="semibold"
          color="primary.main"
        >
          itdesk@restechlabs.com.au
        </Link>
      </Text>
      <Center>
        <Box w={{ md: "100%", lg: "80%" }}>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            gap={4}
            borderWidth="1px"
            rounded="lg"
            p={3}
            mb={3}
            position="relative"
          >
            <Box>
              <Text
                bgColor="primary.100"
                fontWeight="semibold"
                fontSize="lg"
                py={2}
                px={3}
                rounded="full"
                mb={4}
              >
                Step 1
              </Text>
              <UnorderedList>
                <ListItem>
                  Go to{" "}
                  <Link
                    href="https://www.restechlabs.com.au/"
                    target="_blank"
                    fontWeight="semibold"
                    color="primary.main"
                  >
                    www.restechlabs.com.au
                  </Link>{" "}
                  on your web browser
                </ListItem>
                <ListItem>Click "Partner Login"</ListItem>
              </UnorderedList>
            </Box>
            <Box>
              <Image
                src="/images/user-manual/step1.png"
                w="full"
                rounded="lg"
              />
            </Box>
          </SimpleGrid>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            gap={4}
            borderWidth="1px"
            rounded="lg"
            p={3}
            mb={3}
            position="relative"
          >
            <Box>
              <Text
                bgColor="primary.100"
                fontWeight="semibold"
                fontSize="lg"
                py={2}
                px={3}
                rounded="full"
                mb={4}
              >
                Step 2
              </Text>
              <UnorderedList mb={2}>
                <ListItem>Enter your credentials</ListItem>
                <ListItem>Click "Login"</ListItem>
              </UnorderedList>
              <Text>
                Your credentials are provided to you after the client
                registration
              </Text>
            </Box>
            <Box>
              <Image
                src="/images/user-manual/step2.png"
                w="full"
                rounded="lg"
              />
            </Box>
          </SimpleGrid>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            gap={4}
            borderWidth="1px"
            rounded="lg"
            p={3}
            mb={3}
            position="relative"
          >
            <Box>
              <Text
                bgColor="primary.100"
                fontWeight="semibold"
                fontSize="lg"
                py={2}
                px={3}
                rounded="full"
                mb={4}
              >
                Step 3
              </Text>
              <UnorderedList mb={2}>
                <ListItem>
                  Click "New Order" to start collecting your samples
                </ListItem>
              </UnorderedList>
            </Box>
            <Box>
              <Image
                src="/images/user-manual/step3.png"
                w="full"
                rounded="lg"
              />
            </Box>
          </SimpleGrid>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            gap={4}
            borderWidth="1px"
            rounded="lg"
            p={3}
            mb={3}
            position="relative"
          >
            <Box>
              <Text
                bgColor="primary.100"
                fontWeight="semibold"
                fontSize="lg"
                py={2}
                px={3}
                rounded="full"
                mb={4}
              >
                Step 4
              </Text>
              <UnorderedList mb={2}>
                <ListItem>Enter your Company and Contact Details</ListItem>
                <ListItem>Click "Next"</ListItem>
              </UnorderedList>
            </Box>
            <Box>
              <Image
                src="/images/user-manual/step4.png"
                w="full"
                rounded="lg"
              />
            </Box>
          </SimpleGrid>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            gap={4}
            borderWidth="1px"
            rounded="lg"
            p={3}
            mb={3}
            position="relative"
          >
            <Box>
              <Text
                bgColor="primary.100"
                fontWeight="semibold"
                fontSize="lg"
                py={2}
                px={3}
                rounded="full"
                mb={4}
              >
                Step 5
              </Text>
              <UnorderedList mb={2}>
                <ListItem>
                  Select the required order type of Laboratory Analysis, such as
                  Laboratory Composite, Detailed or Validation analysis.
                </ListItem>
                <ListItem>
                  Complete the Sampling Details such as Address, Date and your
                  Security-Sealed Bag information.
                </ListItem>
                <ListItem>Click "Next"</ListItem>
              </UnorderedList>
            </Box>
            <Box>
              <Image
                src="/images/user-manual/step5.png"
                w="full"
                rounded="lg"
              />
            </Box>
          </SimpleGrid>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            gap={4}
            borderWidth="1px"
            rounded="lg"
            p={3}
            mb={3}
            position="relative"
          >
            <Box>
              <Text
                bgColor="primary.100"
                fontWeight="semibold"
                fontSize="lg"
                py={2}
                px={3}
                rounded="full"
                mb={4}
              >
                Step 6
              </Text>
              <UnorderedList mb={2}>
                <ListItem>
                  Add the Number of Samples you would like to collect and enter
                  the details of the Sampling Locations.
                </ListItem>
                <ListItem>Click "Submit"</ListItem>
              </UnorderedList>
              <Flex gap={1}>
                <Text fontWeight="semibold">Note:</Text>
                <Text>
                  Field Blank Samples will automatically be added for compliance
                  for your convenience.
                </Text>
              </Flex>
            </Box>
            <Box>
              <Image
                src="/images/user-manual/step6.png"
                w="full"
                rounded="lg"
              />
            </Box>
          </SimpleGrid>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            gap={4}
            borderWidth="1px"
            rounded="lg"
            p={3}
            mb={3}
            position="relative"
          >
            <Box>
              <Text
                bgColor="primary.100"
                fontWeight="semibold"
                fontSize="lg"
                py={2}
                px={3}
                rounded="full"
                mb={4}
              >
                Step 7
              </Text>
              <UnorderedList mb={2}>
                <ListItem>
                  Please read carefully and tick the Laboratory Analysis Consent
                  form.
                </ListItem>
                <ListItem>
                  Click "Agree and Submit" to complete your NIOSH 9111 Sample
                  Collection.
                </ListItem>
              </UnorderedList>
            </Box>
            <Box>
              <Image
                src="/images/user-manual/step7.png"
                w="full"
                rounded="lg"
              />
            </Box>
          </SimpleGrid>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            gap={4}
            borderWidth="1px"
            rounded="lg"
            p={3}
            mb={3}
            position="relative"
          >
            <Box>
              <Text
                bgColor="primary.100"
                fontWeight="semibold"
                fontSize="lg"
                py={2}
                px={3}
                rounded="full"
                mb={4}
              >
                Step 8
              </Text>
              <UnorderedList mb={2}>
                <ListItem>
                  Great! Your order is submitted. Please place all the samples
                  into Security-Sealed Bag and using your pre-paid satchel post
                  it to us for analysis.
                </ListItem>
              </UnorderedList>
              <Text>
                You can view All your Orders from the Portal Homepage.
              </Text>
            </Box>
            <Box>
              <Image
                src="/images/user-manual/step8.png"
                w="full"
                rounded="lg"
              />
            </Box>
          </SimpleGrid>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            gap={4}
            borderWidth="1px"
            rounded="lg"
            p={3}
            mb={3}
            position="relative"
          >
            <Box>
              <Text
                bgColor="primary.100"
                fontWeight="semibold"
                fontSize="lg"
                py={2}
                px={3}
                rounded="full"
                mb={4}
              >
                Step 9
              </Text>
              <UnorderedList mb={2}>
                <ListItem>
                  Track your Samples in Homepage and if required, to request a
                  Detailed Assessment, click "Request DA" button for your
                  Laboratory Composite Projects.
                </ListItem>
              </UnorderedList>
              <Flex alignItems="center" gap={3} mb={2}>
                <Badge
                  colorScheme="red"
                  rounded="full"
                  h="fit-content"
                  textTransform="capitalize"
                >
                  Pending
                </Badge>
                <Text>Your sample is in transit to us</Text>
              </Flex>
              <Flex alignItems="center" gap={3} mb={2}>
                <Badge
                  colorScheme="blue"
                  rounded="full"
                  h="fit-content"
                  textTransform="capitalize"
                >
                  Received
                </Badge>
                <Text>Our Team received your sample for processing</Text>
              </Flex>
              <Flex alignItems="center" gap={3} mb={2}>
                <Badge
                  colorScheme="green"
                  rounded="full"
                  h="fit-content"
                  textTransform="capitalize"
                >
                  Analysed
                </Badge>
                <Text>Certificate of Analysis is ready to view</Text>
              </Flex>
              <Flex alignItems="center" gap={3} mb={2}>
                <Badge
                  colorScheme="red"
                  rounded="full"
                  h="fit-content"
                  textTransform="capitalize"
                >
                  DA Requested
                </Badge>
                <Text>Detailed Assessment results are processing</Text>
              </Flex>
              <Flex alignItems="center" gap={3} mb={2}>
                <Badge
                  colorScheme="green"
                  rounded="full"
                  h="fit-content"
                  textTransform="capitalize"
                >
                  DA Completed
                </Badge>
                <Text>Detailed Assessment Results are ready to view</Text>
              </Flex>
            </Box>
            <Box>
              <Image
                src="/images/user-manual/step9.png"
                w="full"
                rounded="lg"
              />
            </Box>
          </SimpleGrid>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            gap={4}
            borderWidth="1px"
            rounded="lg"
            p={3}
            mb={3}
            position="relative"
          >
            <Box>
              <Text
                bgColor="primary.100"
                fontWeight="semibold"
                fontSize="lg"
                py={2}
                px={3}
                rounded="full"
                mb={4}
              >
                Step 10
              </Text>
              <UnorderedList mb={2}>
                <ListItem>
                  Click "PDF" button to view all your Certificate of Analysis.
                </ListItem>
              </UnorderedList>
              <Text mb={2}>
                Thank you for using the Digital Chain-of-Custody Platform.
              </Text>
              <Text>
                Your Feedback is important to us. Please contact us at{" "}
                <Link
                  href="mailto:info@restechlabs.com.au"
                  fontWeight="semibold"
                  color="primary.main"
                >
                  info@restechlabs.com.au
                </Link>{" "}
                on how to improve our services
              </Text>
            </Box>
            <Box>
              <Image
                src="/images/user-manual/step10.png"
                w="full"
                rounded="lg"
              />
            </Box>
          </SimpleGrid>
        </Box>
      </Center>
    </ProtectedLayout>
  );
}
