import React, { useState } from "react";
import { ProtectedLayout } from "../layouts";
import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Image,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
  VStack,
  useSteps,
  useToast,
} from "@chakra-ui/react";
import { NewCOCStep1, NewCOCStep2, NewCOCStep3 } from "../components";
import { useNavigate } from "react-router-dom";
import { AuthModel, CocModel } from "../services/models";

export default function NewCOC() {
  const user = AuthModel.getUser();
  const [formData, setFormData] = useState({
    clientName: "",
    contactName: "",
    email: "",
    phone: "",
    samplingFor: "",
    dateSampled: "",
    samplingTechnique: "",
    securitySealNo: "",
    propertyType: "commercial",
    address: "",
    city: "",
    state: "",
    postcode: "",
    rego: "",
    regoState: "",
    VIN: "",
    samples: [
      {
        value: "",
        methylamphetamine: { value: "", color: "" },
        amphetamine: { value: "", color: "" },
        ephedrine: { value: "", color: "" },
        pseudoephedrine: { value: "", color: "" },
        mdma: { value: "", color: "" },
        fentanyl: { value: "", color: "" },
        cocaine: { value: "", color: "" },
      },
    ],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const onNextStep = (stepData) => {
    let newData = { ...formData, ...stepData };
    console.log(newData);
    setFormData(newData);
    if (activeStep === 2) {
      onBusinessInfoSubmit(newData);
    } else {
      setActiveStep(activeStep + 1);
    }
  };
  const onBackStep = (stepData) => {
    let newData = { ...formData, ...stepData };
    setFormData(newData);
    setActiveStep(activeStep - 1);
  };

  const onBusinessInfoSubmit = async (body) => {
    setIsSubmitting(true);
    try {
      body.dateReceived = "";
      body.dateAnalysed = "";
      body.status = "pending";
      body.userId = user._id;
      body.dateSampled = body.dateSampled;
      body.questions = [];
      body.reportType = "manual";
      body.pdfFile = "";

      let response = await CocModel.create(body);
      if (response.status === 200) {
        setActiveStep(activeStep + 1);
      }
    } catch (error) {
      toast({
        description: "Something went wrong!",
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const steps = [
    {
      title: "Client Information",
      content: <NewCOCStep1 onNextStep={onNextStep} formData={formData} />,
    },
    {
      title: "Sampling Details",
      content: (
        <NewCOCStep2
          onNextStep={onNextStep}
          onBackStep={onBackStep}
          formData={formData}
        />
      ),
    },
    {
      title: "Samples Information",
      content: (
        <NewCOCStep3
          onNextStep={onNextStep}
          onBackStep={onBackStep}
          formData={formData}
          isSubmitting={isSubmitting}
        />
      ),
    },
  ];
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  return (
    <ProtectedLayout>
      <Heading fontSize={{ base: "xl", md: "3xl" }}>
        Illicit Drugs On Wipes Digital Chain Of Custody Form
      </Heading>
      {activeStep < 3 ? (
        <>
          <Center my="4">
            <Stepper index={activeStep} w="650px">
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator
                    sx={
                      index <= activeStep
                        ? {
                            background: "#A67C52 !important",
                            color: "white !important",
                          }
                        : {
                            background: "lightgray",
                            color: "white",
                          }
                    }
                  >
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box flexShrink="0" display={{ base: "none", md: "block" }}>
                    <StepTitle fontWeight="600" fontSize="14px">
                      {step.title}
                    </StepTitle>
                  </Box>

                  <StepSeparator
                    sx={
                      index < activeStep
                        ? {
                            background: "#A67C52 !important",
                          }
                        : {
                            background: "lightgray !important",
                          }
                    }
                  />
                </Step>
              ))}
            </Stepper>
          </Center>
          <Box>{steps[activeStep].content}</Box>
        </>
      ) : (
        <Center h="90%">
          <VStack>
            <Image
              src="/images/new-coc-img2.png"
              alt=""
              w={{ base: "50%", lg: "20%" }}
            />
            <Heading>Order Submitted!</Heading>
            <HStack
              gap="3"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                bgColor="black"
                borderRadius="lg"
                fontWeight="medium"
                py="6"
                px="7"
                onClick={() => setActiveStep(0)}
              >
                Another Order
              </Button>
              <Button
                bgColor="black"
                borderRadius="lg"
                fontWeight="medium"
                py="6"
                px="7"
                onClick={() =>
                  user.role === "admin"
                    ? navigate("/")
                    : navigate("/all-orders")
                }
              >
                All Orders
              </Button>
            </HStack>
          </VStack>
        </Center>
      )}
    </ProtectedLayout>
  );
}
