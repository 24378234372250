import React from "react";
import { Box, Stack } from "@chakra-ui/react";
import Topbar from "./partials/Topbar";
import Navbar from "./partials/Navbar";

export default function ProtectedLayout({ children }) {
  return (
    <Box bgColor="primary.100" h="100vh">
      <Topbar />
      <Stack
        flexDirection={{
          base: "column-reverse",
          lg: "row",
        }}
        h="calc(100% - 80px)"
        alignItems={{ base: "center", lg: "start" }}
        justifyContent={"start"}
        position="relative"
        paddingTop={{ base: "70px", lg: 0 }}
      >
        <Navbar />
        <Box
          bgColor="white"
          w={{ base: "92%", md: "96%", lg: "calc(100% - 150px)" }}
          h={{ base: "calc(100% - 175px)", lg: "calc(100% - 20px)" }}
          rounded="2xl"
          p={{ base: "3", md: "6" }}
          overflowY="auto"
          position={{ base: "fixed", lg: "relative" }}
          top={{ base: "70px", lg: "auto" }}
          bottom={0}
        >
          {children}
        </Box>
      </Stack>
    </Box>
  );
}
