import React from "react";
import { AuthModel } from "../../services/models";
import { Button, Heading, Text, useToast } from "@chakra-ui/react";
import { emailSchema } from "../../utils/schemas";
import { useFormik } from "formik";
import { CInput } from "../common";
import { Link } from "react-router-dom";

export default function GetEmail({ moveToNextStep }) {
  const toast = useToast();
  const initialValues = {
    email: "",
  };

  const onEmailSubmit = async (values) => {
    try {
      let response = await AuthModel.getEmail(values);
      if (response.status === 200) {
        toast({
          description: "OTP Sent to Email!",
          status: "success",
          isClosable: true,
        });
        moveToNextStep(values);
      }
    } catch (error) {
      if (error.response.status === 404) {
        toast({
          description: "Email not registered!",
          status: "error",
          isClosable: true,
        });
      } else {
        toast({
          description: "Something went wrong!",
          status: "error",
          isClosable: true,
        });
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: emailSchema,
    onSubmit: onEmailSubmit,
  });

  return (
    <>
      <Heading as="h1" w="full" textAlign={{ base: "center", lg: "start" }}>
        Forgot Password?
      </Heading>
      <Text w="full" textAlign={{ base: "center", lg: "start" }} mb="4">
        Enter your already registered account's email to get started.
      </Text>
      <CInput
        label="Email"
        type="email"
        name="email"
        placeholder="Enter Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        errors={formik.errors.email}
        touched={formik.touched.email}
      />
      <Button
        type="submit"
        onClick={formik.handleSubmit}
        isLoading={formik.isSubmitting}
        loadingText="Loading..."
        bgColor="black"
        w="full"
        borderRadius="lg"
        fontWeight="medium"
        py="6"
        my="2"
      >
        Submit
      </Button>
      <Link
        to="/login"
        style={{
          fontWeight: "600",
          pointerEvents: formik.isSubmitting ? "none" : "auto",
        }}
      >
        Back to Login
      </Link>
    </>
  );
}
