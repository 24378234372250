import React from "react";
import { PublicLayout } from "../layouts";
import { Button, HStack, Heading, Text, useToast } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { CInput } from "../components";
import { useFormik } from "formik";
import { loginSchema } from "../utils/schemas";
import { AuthModel } from "../services/models";

export default function Login() {
  const navigate = useNavigate();
  const toast = useToast();

  const onLogin = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      let response = await AuthModel.login(values);
      AuthModel.setUser(response.data.user);
      AuthModel.storeToken(response.data.token);
      if (response.data.user.role === "admin") {
        navigate("/");
      } else {
        navigate("/all-orders");
      }
    } catch (error) {
      if (error.response?.status === 401) {
        toast({
          description: error.response.data.message,
          status: "error",
          isClosable: true,
        });
      } else {
        toast({
          description: "Something went wrong!",
          status: "error",
          isClosable: true,
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: onLogin,
  });

  return (
    <PublicLayout>
      <Heading
        as="h1"
        w="full"
        textAlign={{ base: "center", lg: "start" }}
        mb="4"
      >
        Login
      </Heading>
      <CInput
        label="Email"
        type="email"
        name="email"
        autocompleted="off"
        placeholder="Enter Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        errors={formik.errors.email}
        touched={formik.touched.email}
      />
      <CInput
        label="Password"
        type="password"
        name="password"
        placeholder="Enter Password"
        value={formik.values.password}
        onChange={formik.handleChange}
        errors={formik.errors.password}
        touched={formik.touched.password}
      />
      <HStack justifyContent="end" w="full">
        <Link
          to="/forgot-password"
          style={{
            fontWeight: "600",
            pointerEvents: formik.isSubmitting ? "none" : "auto",
          }}
        >
          Forgot Password?
        </Link>
      </HStack>
      <Button
        type="submit"
        onClick={formik.handleSubmit}
        isLoading={formik.isSubmitting}
        loadingText="Loading..."
        bgColor="black"
        w="full"
        borderRadius="lg"
        fontWeight="medium"
        py="6"
        my="2"
      >
        Login
      </Button>
    </PublicLayout>
  );
}
