import React from "react";
import { useNavigate } from "react-router-dom";
import { AuthModel } from "../../services/models";
import { Button, Heading, Text, useToast } from "@chakra-ui/react";
import { changePasswordSchema } from "../../utils/schemas";
import { useFormik } from "formik";
import { CInput } from "../common";

export default function ResetPassword({ data }) {
  const toast = useToast();
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const navigate = useNavigate();
  const onResetPassword = async (values) => {
    try {
      let response = await AuthModel.createNewPassword({
        email: data.email,
        password: values.password,
      });
      if (response.status === 200) {
        toast({
          description: "Password Changed Successfully!",
          status: "success",
          isClosable: true,
        });
        navigate("/login");
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast({
          description: "OTP session expired! Please create new OTP again.",
          status: "error",
          isClosable: true,
        });
      } else {
        toast({
          description: "Something went wrong!",
          status: "error",
          isClosable: true,
        });
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: onResetPassword,
  });

  return (
    <>
      <Heading as="h1" w="full" textAlign={{ base: "center", lg: "start" }}>
        Reset Password
      </Heading>
      <Text w="full" textAlign={{ base: "center", lg: "start" }} mb="4">
        You're almost done. Create new password and that's it!
      </Text>
      <CInput
        label="New Password"
        type="password"
        name="password"
        placeholder="Enter Password"
        value={formik.values.password}
        onChange={formik.handleChange}
        errors={formik.errors.password}
        touched={formik.touched.password}
      />
      <CInput
        label="Confirm New Password"
        type="password"
        name="confirmPassword"
        placeholder="Enter Password Again"
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        errors={formik.errors.confirmPassword}
        touched={formik.touched.confirmPassword}
      />
      <Button
        type="submit"
        onClick={formik.handleSubmit}
        isLoading={formik.isSubmitting}
        loadingText="Loading..."
        bgColor="black"
        w="full"
        borderRadius="lg"
        fontWeight="medium"
        py="6"
        my="2"
      >
        Reset
      </Button>
    </>
  );
}
