import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

const theme = extendTheme(
  {
    colors: {
      primary: {
        main: "#A67C52",
        50: "#EEFCFF",
        100: "#fff7f2",
        200: "#fef7f3",
      },
    },
    fonts: {
      heading: `'Poppins', sans-serif`,
      body: `'Poppins', sans-serif`,
    },
    textStyles: {
      h2: {
        fontWeight: "bold",
      },
    },
    components: {
      Checkbox: {
        variants: {
          card: {
            container: {
              borderWidth: "1px",
              borderColor: "gray.200",
              borderRadius: "lg",
              padding: "4",
              alignItems: "start",
              gap: "3",
            },
            control: {
              rounded: "full",
              boxSize: 8,
            },
            icon: {
              fontSize: "16px",
            },
          },
          circular: {
            control: {
              rounded: "full",
            },
          },
        },
      },
    },
  },
  withDefaultColorScheme({
    colorScheme: "primary",
  })
);

export default theme;
