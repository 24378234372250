import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  HStack,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import SampleBox from "./SampleBox";
import { MdAdd } from "react-icons/md";
import { BiX } from "react-icons/bi";

export default function NewCOCStep3({
  onNextStep,
  onBackStep,
  formData,
  isSubmitting,
}) {
  const sampleFormat = {
    value: "",
    methylamphetamine: { value: "<0.02", color: "#83f28f" },
    amphetamine: { value: "<0.02", color: "#83f28f" },
    ephedrine: { value: "<0.02", color: "#83f28f" },
    pseudoephedrine: { value: "<0.02", color: "#83f28f" },
    mdma: { value: "<0.02", color: "#83f28f" },
    fentanyl: { value: "<0.02", color: "#83f28f" },
    cocaine: { value: "<0.02", color: "#83f28f" },
  };
  const [samples, setSamples] = useState(formData.samples);
  const [sampleNo, setSampleNo] = useState();
  const sampleNoRef = useRef(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [checkbox4, setCheckbox4] = useState(false);

  var blankCount = 0;

  const onAddSample = () => {
    let temp = [...samples];
    if (sampleNo) {
      temp = new Array(sampleNo).fill(sampleFormat);
      setSampleNo(null);
      sampleNoRef.current.value = null;
    } else {
      temp.push(sampleFormat);
    }
    setSamples(temp);
  };

  const onEditSample = (index, value) => {
    let newData = [...samples];
    newData[index] = { ...sampleFormat, value: value };
    setSamples(newData);
  };

  const onRemoveSample = (index) => {
    let newData = [...samples];
    newData.splice(index, 1);
    setSamples(newData);
  };

  const onSubmitSamples = () => {
    // if (
    //   formData.samplingFor === "laboratory-composite-order"
    //   &&
    //   samples.length !== 2 &&
    //   samples.length !== 4 &&
    //   samples.length % 5 !== 0
    // ) {
    //   toast({
    //     description: "Only 2,4,5,10,15,20,25... samples allowed!",
    //     status: "error",
    //     isClosable: true,
    //   });
    // } else

    if (samples.some((obj) => obj.value === "")) {
      toast({
        description: "Fill in all fields!",
        status: "error",
        isClosable: true,
      });
    } else {
      onOpen();
    }
  };
  return (
    <>
      <Heading fontSize="xl" mb="4">
        Samples
      </Heading>
      <HStack justifyContent="end">
        <Text>No. of Samples</Text>
        <Input
          ref={sampleNoRef}
          type="number"
          value={sampleNo}
          onChange={(e) => {
            setSampleNo(parseInt(e.target.value));
          }}
          focusBorderColor="primary.main"
          maxW="24"
        />
        <Button
          rightIcon={<MdAdd />}
          onClick={onAddSample}
          bgColor="black"
          borderRadius="lg"
          fontWeight="medium"
          py="6"
          px="7"
          mb="3"
        >
          Add
        </Button>
      </HStack>
      <SimpleGrid
        columns={1}
        spacing={4}
        mb="4"
        p="4"
        borderWidth="1px"
        rounded="lg"
      >
        {samples.map((item, i) => {
          if ((i + 1) % 20 === 1) {
            blankCount++;
            return (
              <>
                <Box pt="1.5">
                  <HStack>
                    <HStack
                      flexDirection={{ base: "column", md: "row" }}
                      w="full"
                      ms={4}
                    >
                      <Input
                        type="text"
                        value={"FB" + blankCount}
                        focusBorderColor="primary.main"
                        readOnly
                      />
                      <Input
                        type="text"
                        value={"Field Blank"}
                        focusBorderColor="primary.main"
                        readOnly
                      />
                    </HStack>
                    <IconButton
                      icon={<BiX />}
                      bgColor="red.100"
                      color="red"
                      size="xs"
                      fontSize="md"
                      isRound
                      isDisabled={true}
                    />
                  </HStack>
                </Box>
                <SampleBox
                  key={i}
                  index={i}
                  data={item}
                  onEditSample={onEditSample}
                  onRemoveSample={onRemoveSample}
                />
              </>
            );
          } else {
            return (
              <SampleBox
                key={i}
                index={i}
                data={item}
                onEditSample={onEditSample}
                onRemoveSample={onRemoveSample}
              />
            );
          }
        })}
      </SimpleGrid>
      <HStack
        justifyContent={
          formData.samplingFor === "laboratory-composite"
            ? "space-between"
            : "end"
        }
      >
        {formData.samplingFor === "laboratory-composite" && (
          <Text fontSize="sm" color="gray.500">
            Note: For Laboratory Composite, only 2, 4, 5, 10, 15, 20, 25... no.
            of samples allowed
          </Text>
        )}
        <HStack>
          <Button
            type="button"
            onClick={() => onBackStep({ ...formData, samples: [...samples] })}
            isLoading={isSubmitting}
            loadingText="Loading..."
            bgColor="black"
            borderRadius="lg"
            fontWeight="medium"
            py="6"
            px="7"
          >
            Back
          </Button>
          {samples.length && (
            <Button
              type="button"
              onClick={() => onSubmitSamples()}
              isLoading={isSubmitting}
              loadingText="Loading..."
              bgColor="black"
              borderRadius="lg"
              fontWeight="medium"
              py="6"
              px="7"
            >
              Submit
            </Button>
          )}
        </HStack>
      </HStack>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        scrollBehavior="inside"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Illicit Drug Residue(s) Laboratory Analysis Consent Form
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack gap={3} alignItems="start">
              <Text>I, the sampler:</Text>
              <Checkbox
                value={checkbox1}
                onChange={(e) => setCheckbox1(e.target.checked)}
                colorScheme="yellow"
              >
                Collected the sample(s) in accordance with the instructions
                outlined in NIOSH Method 9111.
              </Checkbox>
              <Checkbox
                value={checkbox2}
                onChange={(e) => setCheckbox2(e.target.checked)}
                colorScheme="yellow"
              >
                Placed the samples into a security-sealed bag and sent to the
                laboratory for analysis.
              </Checkbox>
              <Checkbox
                value={checkbox3}
                onChange={(e) => setCheckbox3(e.target.checked)}
                colorScheme="yellow"
              >
                Authorize the Restech Laboratories to analyze the sample(s)
                collected at the specified sampling address for illicit drug
                residue(s).
              </Checkbox>
              <Checkbox
                value={checkbox4}
                onChange={(e) => setCheckbox4(e.target.checked)}
                colorScheme="yellow"
              >
                Acknowledge that the sample(s) will be retained in the
                laboratory for a period of one month following the issuance of
                the report.
              </Checkbox>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              onClick={() => onNextStep({ samples: samples })}
              isLoading={isSubmitting}
              loadingText="Loading..."
              isDisabled={!checkbox1 || !checkbox2 || !checkbox3 || !checkbox4}
              bgColor="black"
              borderRadius="lg"
              fontWeight="medium"
              py="6"
              px="7"
            >
              Agree & Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
