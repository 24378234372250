import axios, { BASE_URL } from "../axios";

export default class BaseModel {
  static endPoint = "";

  /**
   * Get All Items
   */
  static get() {
    return axios.get(`${BASE_URL}${this.endPoint}`);
  }

  static getById(id) {
    return axios.get(`${BASE_URL}${this.endPoint}${id}`);
  }

  /**
   * Create or add item to the database.
   * @param {*} body
   * @returns
   */
  static create(body) {
    return axios.post(`${BASE_URL}${this.endPoint}`, body);
  }

  /**
   * Update an item in the database.
   * @param {*} id
   * @param {*} body
   * @returns
   */
  static update(id, body) {
    return axios.put(`${BASE_URL}${this.endPoint}${id}`, body);
  }

  /**
   * Delete an item by id from the database.
   * @param {*} id
   * @returns
   */
  static delete(id) {
    return axios.delete(`${BASE_URL}${this.endPoint}${id}`);
  }
}
