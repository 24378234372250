import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  Progress,
  useToast,
  CircularProgress,
  Text,
} from "@chakra-ui/react";
import { CocModel } from "../../services";

export default function ExportToSlimModal({ data, refetchData }) {
  const [showModal, setShowModal] = useState(false);
  const toast = useToast();

  const uploadToSlim = async () => {
    onShowModal();
    try {
      await CocModel.postToSlims(data._id);
      await refetchData();
      // Success toast
      toast({
        description: "Data has been exported to the SLIMS system!",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      toast({
        description: error.message || "An error occurred!",
        status: "error",
        isClosable: true,
      });
    } finally {
      onHideModal();
    }
  };

  const onShowModal = () => setShowModal(true);
  const onHideModal = () => setShowModal(false);

  return (
    <>
      <Button
        type="button"
        onClick={uploadToSlim}
        bgColor="black"
        borderRadius="lg"
        fontWeight="medium"
        py="6"
        px="7"
        me="2"
      >
        Export to SLIMS
      </Button>
      {showModal && (
        <Modal isOpen={true} onClose={onHideModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <Box
                height={"200px"}
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <CircularProgress size={"40px"} isIndeterminate />
                <Text mt={"10px"}>Exporting Data to the SLIMs</Text>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
