import React from "react";
import {
  Box,
  Divider,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { AiFillEye } from "react-icons/ai";
import ExportToSlimModal from "./ExportToSlimModal";
import { formatDate } from "../../utils";

export default function ViewCOCModal({
  data,
  userRole,
  prepareOrderExcelData,
  prepareSampleExcelData,
  refetchData,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const testTypes = [
    { label: "Validation Analysis Order", value: "validation-analysis-order" },
    {
      label: "Laboratory Composite Order",
      value: "laboratory-composite-order",
    },
    { label: "Detailed Analysis Order", value: "detailed-analysis-order" },
  ];
  var blankCount = 0;

  const questions = [
    "1. Security Seal Number",
    "2. Sampling Address",
    "3. Sampling Date",
    "4. Number of Samples",
    "5. Sampling Locations",
    "6. Are there any discrepancies and/or visible damage/leakage? If Yes - Please record them in the Certificate of Analysis Comments section.",
  ];

  // const formatDate = (value, monthForm) => {
  //   value = value.split("/").reverse().join("-");
  //   value = new Date(value);
  //   return (
  //     value.getDate() +
  //     "-" +
  //     value.toLocaleString("en-us", { month: monthForm }) +
  //     "-" +
  //     value.getFullYear()
  //   );
  // };

  const reloadData = async () => {
    await refetchData();
    onClose();
  };

  return (
    <>
      <Tooltip label="View Digital COC Form">
        <IconButton
          bgColor="primary.100"
          color="primary.main"
          fontSize="xl"
          icon={<AiFillEye />}
          isRound
          onClick={onOpen}
        />
      </Tooltip>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        scrollBehavior="inside"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Digital Chain Of Custody Form</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <SimpleGrid columns={2} spacing={3} mb="3">
                <Box>
                  <Heading as="h6" fontSize="sm">
                    Company Name
                  </Heading>
                  <Text>
                    {data.clientName !== "" ? data.clientName : "N/A"}
                  </Text>
                </Box>
                <Box>
                  <Heading as="h6" fontSize="sm">
                    Contact Name
                  </Heading>
                  <Text>{data.contactName}</Text>
                </Box>
              </SimpleGrid>
              <SimpleGrid columns={2} spacing={3} mb="3">
                <Box>
                  <Heading as="h6" fontSize="sm">
                    Client Email
                  </Heading>
                  <Text>{data.email}</Text>
                </Box>
                <Box>
                  <Heading as="h6" fontSize="sm">
                    Phone
                  </Heading>
                  <Text>{data.phone !== "" ? data.phone : "N/A"}</Text>
                </Box>
              </SimpleGrid>
            </Box>
            <Divider borderColor="primary.main" my="3" />
            <Box>
              <SimpleGrid columns={2} spacing={3} mb="3">
                <Box>
                  <Heading as="h6" fontSize="sm">
                    Date Sampled
                  </Heading>
                  <Text>{formatDate(data.dateSampled)}</Text>
                </Box>
                <Box>
                  <Heading as="h6" fontSize="sm">
                    Order Type
                  </Heading>
                  <Text>
                    {
                      testTypes.find((opt) => opt.value === data.samplingFor)
                        .label
                    }
                  </Text>
                </Box>
              </SimpleGrid>
              <SimpleGrid columns={2} spacing={3} mb="3">
                <Box>
                  <Heading as="h6" fontSize="sm">
                    {data.propertyType !== "vehicle"
                      ? "Property Address"
                      : "Vehicle Details"}
                  </Heading>
                  <Text>
                    {data.propertyType !== "vehicle"
                      ? data.address +
                        ", " +
                        data.city +
                        ", " +
                        data.state +
                        ", " +
                        data.postcode
                      : data.VIN + ", " + data.rego + ", " + data.regoState}
                  </Text>
                </Box>
                <Box>
                  <Heading as="h6" fontSize="sm">
                    Sampling Technique
                  </Heading>
                  <Text>
                    {data.samplingTechnique !== ""
                      ? data.samplingTechnique
                      : "N/A"}
                  </Text>
                </Box>
                <Box>
                  <Heading as="h6" fontSize="sm">
                    Security Seal No.
                  </Heading>
                  <Text>
                    {data.securitySealNo !== "" ? data.securitySealNo : "N/A"}
                  </Text>
                </Box>
                <Box>
                  <Heading as="h6" fontSize="sm" textTransform="capitalize">
                    Testing Location
                  </Heading>
                  <Text textTransform="capitalize">
                    {data.propertyType ? data.propertyType : "N/A"}
                  </Text>
                </Box>
                {/* {data.identifier && (
                  <Box>
                    <Heading as="h6" fontSize="sm">
                      Lab Sample Identifier
                    </Heading>
                    <Text>
                      {formatDate(data.dateCreated, "long").replaceAll(
                        "-",
                        ""
                      ) +
                        "-" +
                        data.identifierName +
                        "-" +
                        data.identifier}
                    </Text>
                  </Box>
                )} */}
              </SimpleGrid>
            </Box>
            <Divider borderColor="primary.main" my="3" />
            <Heading as="h6" fontSize="sm" mb="2">
              Sample Reception Checklist
            </Heading>
            {data.questions.length > 0 ? (
              <Box>
                <Text mb={3}>
                  Please verify that the following information on the sample
                  tubes and the security-sealed bag matches the information
                  stated on the Digital Chain of Custody Form:
                </Text>
                {questions.map((item, i) => {
                  return (
                    <Box key={i} mb={3}>
                      <Heading as={"h6"} fontSize={"sm"}>
                        {item}
                      </Heading>
                      <Text ms={3} mb={1}>
                        {data.questions[i]?.answer ? "Yes" : "No"}
                      </Text>
                      {i < 5
                        ? data.questions[i]?.answer !== null &&
                          !data.questions[i]?.answer && (
                            <>
                              <Heading as={"h6"} fontSize={"sm"} ms={3} mb={1}>
                                Comment
                              </Heading>
                              <Text
                                p={2}
                                border={"1px"}
                                borderColor={"gray.200"}
                                rounded={"lg"}
                                ms={3}
                              >
                                {data.questions[i]?.comment}
                              </Text>
                            </>
                          )
                        : data.questions[i]?.answer !== null &&
                          data.questions[i]?.answer && (
                            <>
                              <Heading as={"h6"} fontSize={"sm"} ms={3} mb={1}>
                                Comment
                              </Heading>
                              <Text
                                p={2}
                                border={"1px"}
                                borderColor={"gray.200"}
                                rounded={"lg"}
                                ms={3}
                              >
                                {data.questions[i]?.comment}
                              </Text>
                            </>
                          )}
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <Text textAlign={"center"} color={"gray"}>
                Not Received Yet
              </Text>
            )}
            <Divider borderColor="primary.main" my="3" />
            <Heading as="h6" fontSize="sm" mb="3">
              Samples
            </Heading>
            <TableContainer>
              <Table variant="striped" borderWidth="1px" mb="2">
                <Thead>
                  <Tr>
                    <Th textAlign="center">Client Reference Number</Th>
                    <Th textAlign="center">Sampling Location</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.samples.map((item, i) => {
                    if ((i + 1) % 20 === 1) {
                      blankCount++;
                      return (
                        <>
                          <Tr>
                            <Td py="2" textAlign="center">
                              {"FB" + blankCount}
                            </Td>
                            <Td py="2" textAlign="center">
                              Field Blank
                            </Td>
                          </Tr>
                          <Tr>
                            <Td py="2" textAlign="center">
                              {i + 1}
                            </Td>
                            <Td py="2" textAlign="center">
                              {item.value}
                            </Td>
                          </Tr>
                        </>
                      );
                    } else {
                      return (
                        <Tr>
                          <Td py="2" textAlign="center">
                            {i + 1}
                          </Td>
                          <Td py="2" textAlign="center">
                            {item.value}
                          </Td>
                        </Tr>
                      );
                    }
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
          {/* Modal Footer */}
          {data.status === "received" && userRole === "admin" && (
            <ModalFooter>
              {!data.slimsOrderId && (
                <ExportToSlimModal data={data} refetchData={reloadData} />
              )}
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
