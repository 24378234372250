import React from "react";
import { Navigate, Route } from "react-router-dom";
import {
  AllCOCs,
  ApprovalPending,
  BusinessInfo,
  COCDeleteRequests,
  ForgotPassword,
  Home,
  Login,
  NewCOC,
  Profile,
  Report,
  Signup,
  Configuration,
  UserManual,
} from "../pages";
import { GuestRoute, ProtectedRoute } from "../components";

export const getRoutes = () => {
  const routes = [
    {
      path: "/login",
      element: (
        <GuestRoute>
          <Login />
        </GuestRoute>
      ),
    },
    {
      path: "/signup",
      element: (
        <GuestRoute>
          <Signup />
        </GuestRoute>
      ),
    },
    {
      path: "/forgot-password",
      element: (
        <GuestRoute>
          <ForgotPassword />
        </GuestRoute>
      ),
    },
    {
      path: "/approval-pending",
      element: (
        <ProtectedRoute>
          <ApprovalPending />
        </ProtectedRoute>
      ),
    },
    {
      path: "/business-info",
      element: (
        <ProtectedRoute>
          <BusinessInfo />
        </ProtectedRoute>
      ),
    },
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      ),
    },
    {
      path: "/all-orders",
      element: (
        <ProtectedRoute>
          <AllCOCs />
        </ProtectedRoute>
      ),
    },
    {
      path: "/new-order",
      element: (
        <ProtectedRoute>
          <NewCOC />
        </ProtectedRoute>
      ),
    },
    {
      path: "/profile",
      element: (
        <ProtectedRoute>
          <Profile />
        </ProtectedRoute>
      ),
    },
    {
      path: "/report/:orderId",
      element: (
        <ProtectedRoute>
          <Report />
        </ProtectedRoute>
      ),
    },
    // {
    //   path: "/coc-delete-requests",
    //   element: (
    //     <ProtectedRoute>
    //       <COCDeleteRequests />
    //     </ProtectedRoute>
    //   ),
    // },
    {
      path: "/configuration",
      element: (
        <ProtectedRoute>
          <Configuration />
        </ProtectedRoute>
      ),
    },
    {
      path: "/user-manual",
      element: (
        <ProtectedRoute>
          <UserManual />
        </ProtectedRoute>
      ),
    },
    {
      path: "*",
      element: <Navigate to="all-orders" />,
    },
  ];
  return (
    <>
      {routes.map((item, i) => {
        return <Route key={i} path={item.path} element={item.element} />;
      })}
    </>
  );
};
