import axios, { BASE_URL } from "../axios";
import BaseModel from "./baseModel";

export default class UserModel extends BaseModel {
  static endPoint = "users/";

  static updatePassword(body) {
    return axios.post(BASE_URL + this.endPoint + "update-password", body);
  }
}
