import React, { useState } from "react";
import { AuthModel } from "../../services/models";
import { Button, HStack, Heading, Text, useToast } from "@chakra-ui/react";
import { CInput } from "../common";

export default function VerifyOTP({ moveToNextStep, data }) {
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [number, setNumber] = useState({
    digit1: "",
    digit2: "",
    digit3: "",
    digit4: "",
  });

  const handleNumber = (event) => {
    const result = event.target.value.replace(/[^0-9]/, "");
    setNumber({
      ...number,
      [event.target.name]: result,
    });

    const { maxLength, value, name } = event.target;
    const fieldIndex = name.slice(-1);
    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 6) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=digit${parseInt(fieldIndex, 10) + 1}]`
        );

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  };

  const onOTPSubmit = async (event) => {
    setIsSubmitting(true);
    event.preventDefault();
    event.stopPropagation();
    if (Object.values(number).some((o) => o === "")) {
      setIsError(true);
      setIsSubmitting(false);
    } else {
      try {
        let formObj = {
          otp: parseInt(Object.values(number).join("")),
          email: data.email,
        };
        let response = await AuthModel.verifyOTP(formObj);
        if (response.status === 200) {
          moveToNextStep(formObj);
        }
      } catch (error) {
        if (error.response.status === 401) {
          toast({
            description: "Invalid OTP!",
            status: "error",
            isClosable: true,
          });
        } else {
          toast({
            description: "Something went wrong!",
            status: "error",
            isClosable: true,
          });
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <Heading as="h1" w="full" textAlign={{ base: "center", lg: "start" }}>
        Verify OTP
      </Heading>
      <Text w="full" textAlign={{ base: "center", lg: "start" }} mb="4">
        We have sent an OTP code to your email. Enter that code to verify that
        it's really you. Your OTP will expire in 10 minutes.
      </Text>
      <form onSubmit={onOTPSubmit}>
        <HStack gap="2">
          <CInput
            name="digit1"
            value={number.digit1}
            onChange={handleNumber}
            maxLength={1}
            autoFocus={true}
            fontSize="2xl"
            textAlign="center"
            py="6"
          />
          <CInput
            name="digit2"
            value={number.digit2}
            onChange={handleNumber}
            maxLength={1}
            fontSize="2xl"
            textAlign="center"
            py="6"
          />
          <CInput
            name="digit3"
            value={number.digit3}
            onChange={handleNumber}
            maxLength={1}
            fontSize="2xl"
            textAlign="center"
            py="6"
          />
          <CInput
            name="digit4"
            value={number.digit4}
            onChange={handleNumber}
            maxLength={1}
            fontSize="2xl"
            textAlign="center"
            py="6"
          />
        </HStack>
        {isError && (
          <Text color="red" mt="2">
            Fill in all fields!
          </Text>
        )}
        <Button
          type="submit"
          isLoading={isSubmitting}
          loadingText="Loading..."
          bgColor="black"
          w="full"
          borderRadius="lg"
          fontWeight="medium"
          py="6"
          mt="3"
        >
          Verify
        </Button>
      </form>
    </>
  );
}
