import React from "react";
import { Box, HStack, IconButton, Input, Text } from "@chakra-ui/react";
import { BiX } from "react-icons/bi";

export default function SampleBox({
  index,
  data,
  onEditSample,
  onRemoveSample,
}) {
  return (
    <Box pt="1.5">
      <HStack>
        <Text fontWeight="bold">{index + 1}.</Text>
        <HStack flexDirection={{ base: "column", md: "row" }} w="full">
          <Input
            type="text"
            value={"Sample #" + (index + 1)}
            focusBorderColor="primary.main"
            readOnly
          />
          <Input
            type="text"
            value={data.value}
            onChange={(e) => {
              let trimVal = e.target.value.replace(/[^A-Z0-9_-\s]/gi, "");
              trimVal = trimVal.replace(/\s\s+/g, " ");
              trimVal = trimVal.substring(0, 30).toUpperCase();
              onEditSample(index, trimVal);
            }}
            placeholder="Enter Sampling Location"
            focusBorderColor="primary.main"
            autoFocus
          />
        </HStack>
        <IconButton
          icon={<BiX />}
          bgColor="red.100"
          color="red"
          size="xs"
          fontSize="md"
          isRound
          onClick={() => {
            onRemoveSample(index);
          }}
          isDisabled={index === 0}
        />
      </HStack>
    </Box>
  );
}
