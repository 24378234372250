import React from "react";
import { Box, HStack, Heading, Image, Stack, Text } from "@chakra-ui/react";
import { formatDate } from '../../utils';

export default function ReportData({ report }) {
  const testTypes = [
    { label: "Validation Analysis Order", value: "validation-analysis-order" },
    {
      label: "Laboratory Composite Order",
      value: "laboratory-composite-order",
    },
    { label: "Detailed Analysis Order", value: "detailed-analysis-order" },
  ];

  return (
    <Stack flexDirection={{ base: "column-reverse", md: "row" }} gap="4">
      <Box width={{ base: "100%", md: "33.3%" }}>
        <Box borderWidth="1px" borderColor="gray.300" mb="4">
          <Heading as="h6" fontSize="sm" backgroundColor="gray.200" p="3">
            Client Information
          </Heading>
          <Box p="3">
            <HStack>
              <Heading as="h6" fontSize="sm">
                Company Name:
              </Heading>
              <Text>{report.clientName}</Text>
            </HStack>
            <HStack>
              <Heading as="h6" fontSize="sm">
                Contact Name:
              </Heading>
              <Text>{report.contactName}</Text>
            </HStack>
            <HStack>
              <Heading as="h6" fontSize="sm">
                Client Email:
              </Heading>
              <Text>{report.email}</Text>
            </HStack>
            <HStack>
              <Heading as="h6" fontSize="sm">
                Phone:
              </Heading>
              <Text>{report.phone !== "" ? report.phone : "N/A"}</Text>
            </HStack>
          </Box>
        </Box>
        <Box borderWidth="1px" borderColor="gray.300">
          <Heading as="h6" fontSize="sm" backgroundColor="gray.200" p="3">
            Progress Dates
          </Heading>
          <Box p="3">
            <HStack>
              <Heading as="h6" fontSize="sm">
                Date Sampled:
              </Heading>
              <Text>{formatDate(report.dateSampled)}</Text>
            </HStack>
            <HStack>
              <Heading as="h6" fontSize="sm">
                Date Received:
              </Heading>
              <Text>
                {report.dateReceived !== ""
                  ? formatDate(report.dateReceived)
                  : "N/A"}
              </Text>
            </HStack>
            <HStack>
              <Heading as="h6" fontSize="sm">
                Date Analysed:
              </Heading>
              <Text>
                {report.dateAnalysed !== ""
                  ? formatDate(report.dateAnalysed)
                  : "N/A"}
              </Text>
            </HStack>
          </Box>
        </Box>
      </Box>
      <Box
        borderWidth="1px"
        borderColor="gray.300"
        height="fit-content"
        width={{ base: "100%", md: "33.3%" }}
      >
        <Heading as="h6" fontSize="sm" backgroundColor="gray.200" p="3">
          Sampling Details
        </Heading>
        <Box p="3">
          <HStack>
            <Heading as="h6" fontSize="sm">
              No. of Samples:
            </Heading>
            <Text>{report.samples.length}</Text>
          </HStack>
          <HStack>
            <Heading as="h6" fontSize="sm">
              Sampling For:
            </Heading>
            <Text>
              {testTypes.find((opt) => opt.value === report.samplingFor).label}
            </Text>
          </HStack>
          <HStack>
            <Heading as="h6" fontSize="sm" whiteSpace="nowrap">
              {report.propertyType !== "vehicle"
                ? "Property Address:"
                : "Vehicle Details"}
            </Heading>
            <Text>
              {report.propertyType !== "vehicle"
                ? report.address +
                  ", " +
                  report.city +
                  ", " +
                  report.state +
                  ", " +
                  report.postcode
                : report.VIN + ", " + report.rego + ", " + report.regoState}
            </Text>
          </HStack>
          <HStack>
            <Heading as="h6" fontSize="sm">
              Sampling Technique:
            </Heading>
            <Text>
              {report.samplingTechnique !== ""
                ? report.samplingTechnique
                : "N/A"}
            </Text>
          </HStack>
          <HStack>
            <Heading as="h6" fontSize="sm">
              Security Seal No:
            </Heading>
            <Text>
              {report.securitySealNo !== "" ? report.securitySealNo : "N/A"}
            </Text>
          </HStack>
          {/* <HStack>
            <Heading as="h6" fontSize="sm">
              Lab Sample Identifier:
            </Heading>
            <Text>
              {formatDate(report.dateCreated, "long").replaceAll("-", "") +
                "-" +
                report.identifierName +
                "-" +
                (report.identifier ?? "N/A")}
            </Text>
          </HStack> */}
        </Box>
      </Box>
      <Box width={{ base: "100%", md: "33.4%" }}>
        <Image src="/images/logo2.svg" alt="" />
        <Heading fontSize="3xl" textAlign="center">
          CERTIFICATE OF ANALYSIS
        </Heading>
      </Box>
    </Stack>
  );
}
