import {
  Box,
  Button,
  HStack,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import SampleCellEditable from "./SampleCellEditable";

export default function SampleTableEditable({
  report,
  onChangeSampleCell,
  onUpdateTable,
  isSubmitting,
  identifierVal,
  setIdentifierVal,
}) {
  var blankCount = 0;

  const getRowCells = (sample, i) => {
    let arr = [];
    for (const key in sample) {
      if (sample[key] instanceof Object) {
        arr.push(
          <Td bgColor={sample[key].color} p="0" m="0" position="relative">
            <SampleCellEditable
              index={i}
              keyName={key}
              value={sample[key].value}
              onChangeSampleCell={onChangeSampleCell}
              isSubmitting={isSubmitting}
            />
          </Td>
        );
      }
    }
    return arr;
  };

  return (
    <Box mt="8">
      <HStack justifyContent="end">
        {/* <Text>Identifier:</Text>
        <Input
          placeholder="Enter Identifier"
          value={identifierVal}
          onChange={(e) => setIdentifierVal(e.target.value)}
          focusBorderColor="primary.main"
          maxW="28"
          isDisabled={isSubmitting}
        /> */}
        <Button
          onClick={onUpdateTable}
          isLoading={isSubmitting}
          loadingText="Loading..."
          bgColor="black"
          borderRadius="lg"
          fontWeight="medium"
          py="6"
          my="2"
        >
          Update Report
        </Button>
      </HStack>
      <TableContainer mt="2">
        <Table colorScheme="gray" borderWidth="1px">
          <Thead>
            <Tr>
              <Th rowSpan={2} bgColor="gray.100">
                Lab Sample ID
              </Th>
              <Th rowSpan={2} bgColor="gray.100">
                Sampling Location
              </Th>
              <Th bgColor="gray.100">Methylamphetamine</Th>
              <Th bgColor="gray.100">Amphetamine</Th>
              <Th bgColor="gray.100">Ephedrine</Th>
              <Th bgColor="gray.100">Pseudoephedrine</Th>
              <Th bgColor="gray.100">MDMA</Th>
              <Th bgColor="gray.100">Fentanyl</Th>
              <Th bgColor="gray.100">Cocaine</Th>
            </Tr>
            <Tr>
              <Th textTransform="lowercase">µg/swab</Th>
              <Th textTransform="lowercase">µg/swab</Th>
              <Th textTransform="lowercase">µg/swab</Th>
              <Th textTransform="lowercase">µg/swab</Th>
              <Th textTransform="lowercase">µg/swab</Th>
              <Th textTransform="lowercase">µg/swab</Th>
              <Th textTransform="lowercase">µg/swab</Th>
            </Tr>
          </Thead>
          <Tbody>
            {report.samples.map((sample, i) => {
              if ((i + 1) % 10 === 1) {
                blankCount++;
                return (
                  <>
                    <Tr>
                      <Td>{"FB" + blankCount}</Td>
                      <Td>Field Blank</Td>
                      <Td bgColor="#83f28f">{"<0.02"}</Td>
                      <Td bgColor="#83f28f">{"<0.02"}</Td>
                      <Td bgColor="#83f28f">{"<0.02"}</Td>
                      <Td bgColor="#83f28f">{"<0.02"}</Td>
                      <Td bgColor="#83f28f">{"<0.02"}</Td>
                      <Td bgColor="#83f28f">{"<0.02"}</Td>
                      <Td bgColor="#83f28f">{"<0.02"}</Td>
                    </Tr>
                    <Tr>
                      <Td py="0">{i + 1}</Td>
                      <Td py="0">{sample.value}</Td>
                      {getRowCells(sample, i).map((cell) => cell)}
                    </Tr>
                  </>
                );
              } else {
                return (
                  <Tr>
                    <Td py="0">{i + 1}</Td>
                    <Td py="0">{sample.value}</Td>
                    {getRowCells(sample, i).map((cell) => cell)}
                  </Tr>
                );
              }
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
