import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";

export const exportExcel = async (data, filename) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const extension = ".xlsx";

  const ws = XLSX.utils.json_to_sheet(data);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const newData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(newData, filename + extension);
};
