import {
  Box,
  Center,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { APP_VERSION } from "../constants";

export default function PublicLayout({ children }) {
  return (
    <Box position={'relative'} overflow={'hidden'}>
      <SimpleGrid columns={{ md: "1", lg: "2" }}>
        <Center h="100vh" p={["10", "14"]} bg="primary.200">
          <VStack w="full" maxW="lg">
            <Box bgColor="white" rounded="lg" py="1" px="2" mb="0">
              <Image src="/images/logo2.svg" alt="" w="120px" />
            </Box>
            {children}
            <Text fontSize="sm" fontWeight="semibold" mt="3">
              Your Digital Lab Partner
            </Text>
          </VStack>
        </Center>
        <VStack
          p="6"
          pt="12"
          gap="3"
          justifyContent="center"
          display={{ base: "none", lg: "flex" }}
        >
          <Image src="/images/public-layout-img2.jpg" alt="" borderRadius="md" />
          <Heading as="h2" size="lg" textAlign="center">
            Digital Chain Of Custody Portal
          </Heading>
          <Text textAlign="center" fontSize="xl">
            Welcome to your Digital Chain of Custody Portal. Use your details to
            login to the portal and start managing your samples. If you require a
            new account please contact TeamRestech at{" "}
            <Link to="mailto:info@restechlabs.com.au" color={'#2fced4'} textDecoration={'underline'}>
              info@restechlabs.com.au
            </Link>{" "}
            or call{" "}
            <Link to="tel:13003 83871" color={'#2fced4'} textDecoration={'underline'}>
              1300 383 871
            </Link>
          </Text>
        </VStack>
      </SimpleGrid>
      <Box position={'absolute'} bottom={4} right={4}>
        <Text>{APP_VERSION}</Text>
      </Box>
    </Box>
  );
}
