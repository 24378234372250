import React from "react";
import { businessInfoStep2Schema } from "../../utils/schemas";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Checkbox,
  HStack,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";

export default function BusinessInfoStep2({ onNextStep }) {
  const formik = useFormik({
    initialValues: {
      paymentType: "subscription",
    },
    validationSchema: businessInfoStep2Schema,
    onSubmit: (values) => onNextStep(values),
  });
  return (
    <Box p={["1", "10"]} pt={["6", "10"]}>
      <HStack justifyContent="space-between" mb="4">
        <Heading as="h2">Payment Option</Heading>
        <Text fontSize="15px" color="gray">
          Step 2/2
        </Text>
      </HStack>
      <Stack direction={["column", "row"]} gap="3" mb="4">
        <Checkbox
          value="subscription"
          onChange={(e) => {
            if (e.target.checked)
              formik.setFieldValue("paymentType", e.target.value);
          }}
          isChecked={formik.values.paymentType === "subscription"}
          colorScheme="cyan"
          variant="card"
          borderColor={
            formik.values.paymentType === "subscription" ? "primary.main" : ""
          }
        >
          <Heading as="h2" size="md" mb="1">
            Monthly Subscription
          </Heading>
          <Text>
            You will have a limited number of report downloads as per your need.
          </Text>
        </Checkbox>
        <Checkbox
          value="post-paid"
          onChange={(e) => {
            if (e.target.checked)
              formik.setFieldValue("paymentType", e.target.value);
          }}
          isChecked={formik.values.paymentType === "post-paid"}
          colorScheme="cyan"
          variant="card"
          borderColor={
            formik.values.paymentType === "post-paid" ? "primary.main" : ""
          }
        >
          <Heading as="h2" size="md" mb="1">
            Post Paid
          </Heading>
          <Text>Download as much reports as you want and pay later.</Text>
        </Checkbox>
      </Stack>
      <HStack justifyContent="end">
        <Button
          type="submit"
          onClick={formik.handleSubmit}
          isLoading={formik.isSubmitting}
          loadingText="Loading..."
          bgColor="black"
          borderRadius="lg"
          fontWeight="medium"
          py="6"
          px="7"
        >
          Finish
        </Button>
      </HStack>
    </Box>
  );
}
