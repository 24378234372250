import React, { useRef } from "react";
import { detailedAnalysisOrderSchema } from "../../utils/schemas";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { LuFileUp } from "react-icons/lu";
import { FaFilePdf, FaPlus } from "react-icons/fa6";
import { FaTimesCircle } from "react-icons/fa";

const ImageUpload = ({ onChange }) => {
  const inputRef = useRef();

  return (
    <Box>
      <Button
        bgColor="black"
        color="white"
        rounded="lg"
        fontWeight="medium"
        rightIcon={<FaPlus />}
        onClick={() => inputRef.current.click()}
      >
        Add File
      </Button>
      <input
        ref={inputRef}
        type="file"
        name="DAFile"
        accept={"application/pdf"}
        onChange={onChange}
        style={{ display: "none" }}
      />
    </Box>
  );
};

export default function DetailedAnalysisOrderModal({
  data,
  statusOptions,
  onUpdateStatus,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      DAFile: "",
    },
    validationSchema: detailedAnalysisOrderSchema,
    onSubmit: (values) => {
      let files = [...data.orderFiles];
      files.push({ fileName: "DAFile", fileURL: values.DAFile });
      onUpdateStatus({ ...data, orderFiles: files }, statusOptions[4].value);
      onClose();
    },
  });

  return (
    <>
      <Tooltip label="Upload DA Report">
        <IconButton
          onClick={onOpen}
          bgColor="primary.main"
          color="primary.100"
          fontSize="xl"
          icon={<LuFileUp />}
          isRound
        />
      </Tooltip>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        scrollBehavior="inside"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Detailed Analysis Report</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight="semibold" mb={3}>
              Upload File
            </Text>
            <ImageUpload
              onChange={(e) => {
                if (e.target.files[0].name.split(".").pop() === "pdf") {
                  formik.setFieldValue("DAFile", e.target.files[0]);
                } else {
                  toast({
                    description: "Only PDF files allowed!",
                    status: "error",
                    isClosable: true,
                  });
                }
              }}
            />
            {formik.values.DAFile && formik.values.DAFile !== "" && (
              <Flex
                p={2}
                mt={3}
                borderWidth="1px"
                rounded="lg"
                gap={2}
                justifyContent="space-between"
                w="fit-content"
              >
                <Flex gap={2}>
                  <Icon as={FaFilePdf} fontSize="20px" />
                  <Text fontWeight="semibold" fontSize="sm">
                    {formik.values.DAFile.name}
                  </Text>
                </Flex>
              </Flex>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              onClick={formik.handleSubmit}
              isLoading={formik.isSubmitting}
              bgColor="black"
              borderRadius="lg"
              fontWeight="medium"
              py="6"
              px="7"
              me="2"
            >
              Submit
            </Button>
            <Button
              onClick={onClose}
              isDisabled={formik.isSubmitting}
              bgColor="gray.200"
              color="black"
              borderRadius="lg"
              fontWeight="medium"
              py="6"
              px="7"
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
