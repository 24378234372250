import {
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { FaFilePdf } from "react-icons/fa6";
import { ASSET_URL } from "../../services";
import { MdFileDownload } from "react-icons/md";
import { LuFileDown } from "react-icons/lu";

export default function DetailedAnalysisReportModal({ data }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const downloadPDF = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <Tooltip label="View DA Report">
        <IconButton
          onClick={onOpen}
          bgColor="primary.main"
          color="primary.100"
          fontSize="xl"
          icon={<LuFileDown />}
          isRound
        />
      </Tooltip>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        scrollBehavior="inside"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detailed Analysis Report</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {data && (
              <Flex
                p={2}
                borderWidth="1px"
                rounded="lg"
                gap={2}
                justifyContent="space-between"
                cursor="pointer"
                w="fit-content"
                onClick={() => downloadPDF(ASSET_URL + data?.fileURL)}
              >
                <Flex gap={2}>
                  <Icon as={FaFilePdf} fontSize="20px" />
                  <Text fontWeight="semibold" fontSize="sm">
                    {data?.fileURL}
                  </Text>
                </Flex>
                <Icon
                  as={MdFileDownload}
                  color="primary.main"
                  fontSize="20px"
                />
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
