import React from "react";
import { useFormik } from "formik";
import { newCOCStep2Schema } from "../../utils/schemas";
import {
  Button,
  Divider,
  HStack,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CInput, CSelect } from "../common";
import { AddressAutofill } from "@mapbox/search-js-react";
import { MAPBOX_ACCESS_TOKEN } from "../../services/axios";

export default function NewCOCStep2({ onNextStep, onBackStep, formData }) {
  const samplingForList = [
    { label: "Validation Analysis Order", value: "validation-analysis-order" },
    {
      label: "Laboratory Composite Order",
      value: "laboratory-composite-order",
    },
    { label: "Detailed Analysis Order", value: "detailed-analysis-order" },
  ];
  const samplingTechniqueList = [
    { label: "NIOSH 9111 Swabs", value: "NIOSH 9111 Swabs" },
  ];
  const statesList = [
    { label: "New South Wales", value: "New South Wales" },
    { label: "Victoria", value: "Victoria" },
    { label: "Queensland", value: "Queensland" },
    { label: "Western Australia", value: "Western Australia" },
    { label: "South Australia", value: "South Australia" },
    { label: "Tasmania", value: "Tasmania" },
    {
      label: "Australian Capital Territory",
      value: "Australian Capital Territory",
    },
    { label: "Northern Territory", value: "Northern Territory" },
  ];
  const formik = useFormik({
    initialValues: formData,
    validationSchema: newCOCStep2Schema,
    onSubmit: (values) => onNextStep(values),
  });
  return (
    <form>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={3} mb="4">
        <CSelect
          label="Order Type"
          options={samplingForList}
          name="samplingFor"
          placeholder="Select Order Type"
          defaultValue={samplingForList.find(
            (item) => item.value === formik.values.samplingFor
          )}
          onChange={(opt) => formik.setFieldValue("samplingFor", opt.value)}
          errors={formik.errors.samplingFor}
          touched={formik.touched.samplingFor}
        />
        <CInput
          type="date"
          label="Date Sampled"
          name="dateSampled"
          value={formik.values.dateSampled}
          onChange={formik.handleChange}
          errors={formik.errors.dateSampled}
          touched={formik.touched.dateSampled}
          max={new Date().toISOString().split("T")[0]}
        />
        <CSelect
          label="Sampling Technique"
          options={samplingTechniqueList}
          name="samplingTechnique"
          placeholder="Select from list"
          defaultValue={samplingTechniqueList.find(
            (item) => item.value === formik.values.samplingTechnique
          )}
          onChange={(opt) =>
            formik.setFieldValue("samplingTechnique", opt.value)
          }
          errors={formik.errors.samplingTechnique}
          touched={formik.touched.samplingTechnique}
        />
        <CInput
          label="Security Seal No."
          name="securitySealNo"
          placeholder="Enter Security Seal No."
          value={formik.values.securitySealNo}
          onChange={formik.handleChange}
          errors={formik.errors.securitySealNo}
          touched={formik.touched.securitySealNo}
        />
        <VStack alignItems="start">
          <Text fontWeight="semibold">Testing Location</Text>
          <RadioGroup name="propertyType" value={formik.values.propertyType}>
            <Stack
              flexDirection={{ base: "column", md: "row" }}
              gap={{ base: 3, md: 8 }}
            >
              <Radio
                value="commercial"
                onChange={(e) => {
                  if (e.target.checked) {
                    formik.setFieldValue("propertyType", "commercial");
                  }
                }}
                colorScheme="yellow"
              >
                Commercial Property
              </Radio>
              <Radio
                value="residential"
                onChange={(e) => {
                  if (e.target.checked) {
                    formik.setFieldValue("propertyType", "residential");
                  }
                }}
                colorScheme="yellow"
              >
                Residential Property
              </Radio>
              <Radio
                value="vehicle"
                onChange={(e) => {
                  if (e.target.checked) {
                    formik.setFieldValue("propertyType", "vehicle");
                  }
                }}
                colorScheme="yellow"
              >
                Vehicle
              </Radio>
            </Stack>
          </RadioGroup>
        </VStack>
      </SimpleGrid>
      <Divider />
      <Text fontWeight="medium" fontSize="14px" my="3" color="gray">
        {formik.values.propertyType !== "vehicle"
          ? "Property Address"
          : "Vehicle Details"}
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={3} mb="4">
        {formik.values.propertyType !== "vehicle" ? (
          <>
            <AddressAutofill
              accessToken={MAPBOX_ACCESS_TOKEN}
              options={{ country: "AU" }}
            >
              <CInput
                label="Street Address"
                name="address"
                autoComplete="address-line1"
                placeholder="Enter Address"
                value={formik.values.address}
                onChange={(e) =>
                  formik.setFieldValue("address", e.target.value)
                }
                errors={formik.errors.address}
                touched={formik.touched.address}
              />
            </AddressAutofill>
            <CInput
              label="City"
              name="city"
              autoComplete="address-level2"
              placeholder="Enter City"
              value={formik.values.city}
              onChange={formik.handleChange}
              errors={formik.errors.city}
              touched={formik.touched.city}
            />
            <CInput
              label="State"
              name="state"
              autoComplete="address-level1"
              placeholder="Enter State"
              value={formik.values.state}
              onChange={formik.handleChange}
              errors={formik.errors.state}
              touched={formik.touched.state}
            />
            <CInput
              label="Postcode"
              name="postcode"
              autoComplete="postal-code"
              placeholder="Enter Postcode"
              value={formik.values.postcode}
              onChange={formik.handleChange}
              errors={formik.errors.postcode}
              touched={formik.touched.postcode}
            />
          </>
        ) : (
          <>
            <CInput
              label="Registration Number"
              name="rego"
              placeholder="Enter Registration Number"
              value={formik.values.rego}
              onChange={(e) => {
                let formattedValue = e.target.value.replace(/[^A-Z0-9]/gi, "");
                formik.setFieldValue(
                  "rego",
                  formattedValue.substring(0, 7).toUpperCase()
                );
              }}
              errors={formik.errors.rego}
              touched={formik.touched.rego}
            />
            <CSelect
              label="State of Registration"
              options={statesList}
              name="regoState"
              placeholder="Select State of Registration"
              defaultValue={statesList.find(
                (item) => item.value === formik.values.regoState
              )}
              onChange={(opt) => {
                formik.setFieldValue("regoState", opt.value);
              }}
              errors={formik.errors.regoState}
              touched={formik.touched.regoState}
            />
            <CInput
              label="VIN Number"
              name="VIN"
              placeholder="Enter VIN Number"
              value={formik.values.VIN}
              onChange={(e) => {
                let formattedValue = e.target.value.replace(/[^A-Z0-9]/gi, "");
                formik.setFieldValue(
                  "VIN",
                  formattedValue.substring(0, 17).toUpperCase()
                );
              }}
              errors={formik.errors.VIN}
              touched={formik.touched.VIN}
            />
          </>
        )}
      </SimpleGrid>
      <HStack justifyContent="end">
        <Button
          type="button"
          onClick={() => onBackStep(formik.values)}
          isLoading={formik.isSubmitting}
          loadingText="Loading..."
          bgColor="black"
          borderRadius="lg"
          fontWeight="medium"
          py="6"
          px="7"
        >
          Back
        </Button>
        <Button
          type="submit"
          onClick={formik.handleSubmit}
          isLoading={formik.isSubmitting}
          loadingText="Loading..."
          bgColor="black"
          borderRadius="lg"
          fontWeight="medium"
          py="6"
          px="7"
        >
          Next
        </Button>
      </HStack>
    </form>
  );
}
