import React, { useState } from "react";
import { Button, VStack, useToast } from "@chakra-ui/react";
import { CInput } from "../common";
import { useFormik } from "formik";
import { ChangeProfileDataSchema } from "../../utils/schemas";
import { AuthModel, UserModel } from "../../services/models";

export default function ChangeProfileData() {
  const [user, setUser] = useState(AuthModel.getUser());
  const toast = useToast();

  const onChangeData = async (values) => {
    try {
      let body = { ...user };
      body.firstName = values.firstName;
      body.lastName = values.lastName;
      await UserModel.update(body._id, body);
      setUser(body);
      AuthModel.setUser(body);
      toast({
        description: "Data changed successfully!",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      toast({
        description: "Something went wrong!",
        status: "error",
        isClosable: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
    },
    validationSchema: ChangeProfileDataSchema,
    onSubmit: onChangeData,
  });
  return (
    <>
      <VStack gap="3">
        <CInput
          label="First Name"
          name="firstName"
          placeholder="Enter First Name"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          errors={formik.errors.firstName}
          touched={formik.touched.firstName}
        />
        <CInput
          label="Last Name"
          name="lastName"
          placeholder="Enter Last Name"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          errors={formik.errors.lastName}
          touched={formik.touched.lastName}
        />
      </VStack>
      <Button
        type="submit"
        onClick={formik.handleSubmit}
        isLoading={formik.isSubmitting}
        loadingText="Loading..."
        bgColor="black"
        borderRadius="lg"
        fontWeight="medium"
        py="6"
        px="7"
        mt="4"
      >
        Update
      </Button>
    </>
  );
}
