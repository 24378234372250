import React, { useEffect, useState } from "react";
import { AuthModel } from "../../services/models";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { Loader } from '../common';

export default function ProtectedRoute({ children }) {
  const { isLoggedIn, toggleLogin } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const result = await AuthModel.verifyToken();
        if (result) {
          toggleLogin(true);
        } else {
          throw new Error('Invalid token');
        }
      } catch (error) {
        AuthModel.logout();
        toggleLogin(false);
      } finally {
        setLoading(false);
      }
    };

    const token = AuthModel.getToken();

    if (token) {
      checkTokenValidity();
    } else {
      AuthModel.logout();
      toggleLogin(false);
      setLoading(false);
    }
  }, [toggleLogin]);

  if (loading) {
    return <Loader />; // Show loading state while checking token validity
  }

  return isLoggedIn ? children : <Navigate to="/login" />;
}
