import React, { useRef, useState } from "react";
import { CInput } from "../common";
import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { MdColorize } from "react-icons/md";
import { CirclePicker } from "react-color";

export default function SampleCellEditable({
  index,
  keyName,
  value,
  onChangeSampleCell,
  isSubmitting,
}) {
  const [showPicker, setShowPicker] = useState(false);
  const iconRef = useRef();
  return (
    <Box
      onMouseEnter={() => (iconRef.current.style.display = "flex")}
      onMouseLeave={() => (iconRef.current.style.display = "none")}
    >
      <CInput
        value={value}
        bgColor="transparent"
        border="none"
        rounded="0"
        height="50px"
        onChange={(e) => {
          onChangeSampleCell(index, keyName, e.target.value, "value");
        }}
        isDisabled={isSubmitting}
      />
      <Button
        ref={iconRef}
        onClick={() => setShowPicker(!showPicker)}
        display="none"
        bgColor="white"
        color="primary.main"
        borderRadius="50px"
        p="0"
        position="absolute"
        right="0"
        top="0"
        zIndex="10"
      >
        <MdColorize />
      </Button>
      {showPicker && (
        <Modal
          isOpen={showPicker}
          onClose={() => {
            setShowPicker(!showPicker);
            iconRef.current.style.display = "none";
          }}
          size="lg"
          scrollBehavior="inside"
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Change Cell Color</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb="4">
              <CirclePicker
                width="100%"
                colors={[
                  "#ffa29c",
                  "#ff80ab",
                  "#ef8fff",
                  "#d0b9fa",
                  "#99a9ff",
                  "#61b9ff",
                  "#8bd9fc",
                  "#82f1ff",
                  "#9efff6",
                  "#81f785",
                  "#c4ff80",
                  "#effc6f",
                  "#ffed4d",
                  "#ffc20a",
                  "#ffbe5e",
                  "#ff835c",
                  "#d9a796",
                  "#bfd0d9",
                ]}
                onChange={(val) =>
                  onChangeSampleCell(index, keyName, val.hex, "color")
                }
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}
