import React, { useEffect, useState } from "react";
import ProtectedLayout from "../layouts/ProtectedLayout";
import {
  HStack,
  Heading,
  Image,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  FaFileCircleExclamation,
  FaFileImport,
  FaFileCircleCheck,
} from "react-icons/fa6";
import { CountIconBox } from "../components";
import { ExtrasModel } from "../services/models";

export default function Home() {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        let response = await ExtrasModel.getCocCount();
        if (response.status === 200) {
          let pendingNum = response.data.data.find(
            (obj) => obj._id === "pending"
          );
          let receivedNum = response.data.data.find(
            (obj) => obj._id === "received"
          );
          let analysedNum = response.data.data.find(
            (obj) => obj._id === "analysed"
          );
          let statsItems = [
            {
              icon: FaFileCircleExclamation,
              number: pendingNum ? pendingNum.count : "0",
              title: "Pending Orders",
            },
            {
              icon: FaFileImport,
              number: receivedNum ? receivedNum.count : "0",
              title: "Received Orders",
            },
            {
              icon: FaFileCircleCheck,
              number: analysedNum ? analysedNum.count : "0",
              title: "Analysed Orders",
            },
          ];
          setStats(statsItems);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchStats();
  }, []);

  return (
    <ProtectedLayout>
      <VStack>
        <VStack mt={{ base: "0", md: "6" }} mb="6">
          <Heading textAlign="center" fontSize={{ base: "2xl", lg: "4xl" }}>
            Welcome To Restech Laboratories
          </Heading>
          <Text textAlign="center">
            Get your samples analyzed and download reports without hustle.
          </Text>
        </VStack>
        <HStack gap="5" wrap="wrap" justifyContent="center" zIndex="1">
          {loading ? (
            <Spinner />
          ) : (
            stats.map((item, i) => {
              return <CountIconBox {...item} key={i} />;
            })
          )}
        </HStack>
      </VStack>
      <Image
        src="/images/home-img2.png"
        alt=""
        w="200px"
        position="absolute"
        bottom={{ base: "100px", lg: "20px" }}
        right="30px"
        zIndex="0"
      />
    </ProtectedLayout>
  );
}
