import React from "react";
import { ProtectedLayout } from "../layouts";
import {
  Avatar,
  Box,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import { ChangePassword, ChangeProfileData } from "../components";

export default function Profile() {
  return (
    <ProtectedLayout>
      <Heading mb="4" fontSize={{ base: "xl", md: "3xl" }}>
        Profile
      </Heading>
      <Stack gap="3" flexDirection={{ base: "column", md: "row" }}>
        <VStack
          gap="4"
          w={{ base: "full", md: "40%", lg: "30%" }}
          mt={{ base: "0", md: "10" }}
          mb={{ base: "4", md: "0" }}
        >
          <Box w={{ base: "40%", md: "50%" }}>
            <Avatar
              src=""
              size="full"
              sx={{ borderWidth: "3px", borderColor: "primary.main" }}
            />
          </Box>
        </VStack>
        <Box flexGrow="1">
          <Tabs variant="unstyled">
            <TabList borderBottomWidth="2px" borderColor="primary.main">
              <Tab
                borderWidth="2px"
                borderBottomWidth="0"
                borderColor="gray.300"
                borderTopLeftRadius="10px"
                borderTopRightRadius="10px"
                color="gray.400"
                fontWeight="bold"
                _selected={{
                  borderColor: "primary.main",
                  color: "primary.main",
                }}
              >
                Account
              </Tab>
              <Tab
                borderWidth="2px"
                borderBottomWidth="0"
                borderColor="gray.300"
                borderTopLeftRadius="10px"
                borderTopRightRadius="10px"
                color="gray.400"
                fontWeight="bold"
                _selected={{
                  borderColor: "primary.main",
                  color: "primary.main",
                }}
              >
                Change Password
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ChangeProfileData />
              </TabPanel>
              <TabPanel>
                <ChangePassword />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Stack>
    </ProtectedLayout>
  );
}
