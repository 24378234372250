import React from "react";
import { Button, VStack, useToast } from "@chakra-ui/react";
import { CInput } from "../common";
import { useFormik } from "formik";
import { changePasswordSchema } from "../../utils/schemas";
import { AuthModel, UserModel } from "../../services/models";

export default function ChangePassword() {
  const user = AuthModel.getUser();
  const toast = useToast();

  const onChangePassword = async (values, { resetForm }) => {
    try {
      let body = { _id: user._id, password: values.password };
      await UserModel.updatePassword(body);
      toast({
        description: "Password changed successfully!",
        status: "success",
        isClosable: true,
      });
      resetForm();
    } catch (error) {
      toast({
        description: "Something went wrong!",
        status: "error",
        isClosable: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: onChangePassword,
  });
  return (
    <>
      <VStack gap="3">
        <CInput
          type="password"
          label="New Password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          errors={formik.errors.password}
          touched={formik.touched.password}
        />
        <CInput
          type="password"
          label="Confirm Password"
          name="confirmPassword"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          errors={formik.errors.confirmPassword}
          touched={formik.touched.confirmPassword}
        />
      </VStack>
      <Button
        type="submit"
        onClick={formik.handleSubmit}
        isLoading={formik.isSubmitting}
        loadingText="Loading..."
        bgColor="black"
        borderRadius="lg"
        fontWeight="medium"
        py="6"
        px="7"
        mt="4"
      >
        Update
      </Button>
    </>
  );
}
