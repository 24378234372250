import axios from "axios";
import { AuthModel } from "./models";

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const ASSET_URL =
  "https://restechlabs-storage.s3.ap-southeast-2.amazonaws.com/";
export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiZm9saW9tYW5hZ2VyIiwiYSI6ImNscTNqY2I5MzBlYWsyc2x4MW5nbWxodzUifQ.Pfz6iwJgGMNEETu0zBrv_A";

axios.interceptors.request.use(
  (config) => {
    if (config.authorization !== false) {
      const token = AuthModel.getToken();
      if (token) {
        config.headers.Authorization = "Bearer " + token;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
