import React from "react";
import { Box, Button, HStack, Heading, Text, VStack } from "@chakra-ui/react";
import { useFormik } from "formik";
import { businessInfoStep1Schema } from "../../utils/schemas";
import { CInput } from "../common";

export default function BusinessInfoStep1({ onNextStep }) {
  const formik = useFormik({
    initialValues: {
      businessName: "",
      businessPhone: "",
      businessAddress: "",
      monthlyReports: 1,
    },
    validationSchema: businessInfoStep1Schema,
    onSubmit: (values) => onNextStep(values),
  });
  return (
    <Box p={["1", "10"]} pt={["6", "10"]}>
      <HStack justifyContent="space-between" mb="4">
        <Heading as="h2">Business Info</Heading>
        <Text fontSize="15px" color="gray">
          Step 1/2
        </Text>
      </HStack>
      <VStack gap="4" mb="4">
        <CInput
          label="Business Name"
          name="businessName"
          placeholder="Enter Business Name"
          value={formik.values.businessName}
          onChange={formik.handleChange}
          errors={formik.errors.businessName}
          touched={formik.touched.businessName}
        />
        <CInput
          label="Business Phone"
          name="businessPhone"
          placeholder="Enter Business Phone"
          value={formik.values.businessPhone}
          onChange={formik.handleChange}
          errors={formik.errors.businessPhone}
          touched={formik.touched.businessPhone}
        />
        <CInput
          label="Business Address"
          name="businessAddress"
          placeholder="Enter Business Address"
          value={formik.values.businessAddress}
          onChange={formik.handleChange}
          errors={formik.errors.businessAddress}
          touched={formik.touched.businessAddress}
        />
        <CInput
          label="Estimated Monthly Reports"
          type="number"
          name="monthlyReports"
          placeholder="Enter No. of Reports"
          min={1}
          value={formik.values.monthlyReports}
          onChange={(_, value) =>
            formik.setFieldValue(
              "monthlyReports",
              !value || value < 1 ? 1 : value
            )
          }
          onBlur={(e) =>
            !e.target.value || e.target.value < 1
              ? formik.setFieldValue("monthlyReports", e.target.value)
              : ""
          }
          errors={formik.errors.monthlyReports}
          touched={formik.touched.monthlyReports}
        />
      </VStack>
      <HStack justifyContent="end">
        <Button
          type="submit"
          onClick={formik.handleSubmit}
          isLoading={formik.isSubmitting}
          loadingText="Loading..."
          bgColor="black"
          borderRadius="lg"
          fontWeight="medium"
          py="6"
          px="7"
        >
          Next
        </Button>
      </HStack>
    </Box>
  );
}
