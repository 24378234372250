import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";

export default function ReportFooter() {
  return (
    <Stack mt="8" flexDirection={{ base: "column", lg: "row" }} gap="6">
      <Box borderWidth="1px" width={{ base: "100%", lg: "75%" }}>
        <Heading as="h6" fontSize="sm" backgroundColor="gray.200" p="2">
          Methodology Information
        </Heading>
        <Text backgroundColor="gray.50" p="2" fontSize="14px">
          Samples analysed as received. Contact Sampling Agency for the result
          interpretation. Limit of reporting is 0.02 µg/swab for all analytes.
        </Text>
        <Text p="2" fontSize="14px">
          In-House Method LTM-001 - Illicit Drugs on Wipes (IDOW) used for
          quantitation by LC-QQQ. Upper Limit of reporting is 100.0 µg/swab for
          all analytes.
        </Text>
        <Text backgroundColor="gray.50" p="2" fontSize="14px">
          Uncertainty of measurement for each analyte is ≤ 15% µg/swab given at
          95% confidence level. (k=2)
        </Text>
        <Text p="2" fontSize="14px">
          Reports are simplified for readability. For all enquiries regarding to
          this Certificate of Analysis please email to info@restechlabs.com.au
        </Text>
      </Box>
      <Box
        borderWidth="1px"
        height="fit-content"
        width={{ base: "100%", lg: "25%" }}
      >
        <Heading
          as="h6"
          fontSize="sm"
          textAlign="end"
          backgroundColor="gray.200"
          p="2"
        >
          Authorised Signatory
        </Heading>
        <Heading
          as="h6"
          fontSize="sm"
          textAlign="end"
          backgroundColor="gray.50"
          p="2"
        >
          Laboratory Director: Alp Bekensir
        </Heading>
      </Box>
    </Stack>
  );
}
