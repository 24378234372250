import React from "react";
import { Box, Heading, Icon, Text, VStack } from "@chakra-ui/react";

export default function CountIconBox({ icon, number, title }) {
  return (
    <VStack rounded="2xl" bgColor="white" shadow="md" py="5" px="6" w="200px">
      <Box
        bgColor="primary.100"
        rounded="full"
        w="fit-content"
        px="4"
        pt="4"
        pb="2"
      >
        <Icon as={icon} color="primary.main" fontSize="5xl" />
      </Box>
      <Heading>{number}</Heading>
      <Text color="primary.main" textAlign="center">
        {title}
      </Text>
    </VStack>
  );
}
