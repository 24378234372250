import React from "react";
import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import Select from "react-select";

export default function CSelect({
  label,
  options,
  labelProps,
  touched,
  errors,
  ...selectProps
}) {
  return (
    <FormControl isInvalid={errors && touched}>
      <FormLabel fontWeight="semibold" {...labelProps}>
        {label}
      </FormLabel>
      <Select
        options={options}
        {...selectProps}
        styles={{
          control: (styles, { isFocused, isSelected }) => {
            return {
              ...styles,
              borderWidth: errors && touched ? "2px" : "1px",
              borderColor:
                isFocused || isSelected
                  ? "#A67C52"
                  : errors && touched
                  ? "#E53E3E"
                  : "#E2E8F0",
              borderRadius: "0.5rem",
              outline: "0",
              boxShadow: "0",
            };
          },
        }}
      />
      {errors && touched && <FormErrorMessage>{errors}</FormErrorMessage>}
    </FormControl>
  );
}
