import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";

export default function SampleTable({ report }) {
  var blankCount = 0;
  return (
    <TableContainer mt="8">
      <Table colorScheme="gray" borderWidth="1px">
        <Thead>
          <Tr>
            <Th rowSpan={2} bgColor="gray.100">
              Lab Sample ID
            </Th>
            <Th rowSpan={2} bgColor="gray.100">
              Sampling Location
            </Th>
            <Th bgColor="gray.100">Methylamphetamine</Th>
            <Th bgColor="gray.100">Amphetamine</Th>
            <Th bgColor="gray.100">Ephedrine</Th>
            <Th bgColor="gray.100">Pseudoephedrine</Th>
            <Th bgColor="gray.100">MDMA</Th>
            <Th bgColor="gray.100">Fentanyl</Th>
            <Th bgColor="gray.100">Cocaine</Th>
          </Tr>
          <Tr>
            <Th textTransform="lowercase">µg/swab</Th>
            <Th textTransform="lowercase">µg/swab</Th>
            <Th textTransform="lowercase">µg/swab</Th>
            <Th textTransform="lowercase">µg/swab</Th>
            <Th textTransform="lowercase">µg/swab</Th>
            <Th textTransform="lowercase">µg/swab</Th>
            <Th textTransform="lowercase">µg/swab</Th>
          </Tr>
        </Thead>
        <Tbody>
          {report.samples.map((sample, i) => {
            if ((i + 1) % 20 === 1) {
              blankCount++;
              return (
                <>
                  <Tr>
                    <Td>{"FB" + blankCount}</Td>
                    <Td>Field Blank</Td>
                    <Td bgColor="#83f28f">{"<0.02"}</Td>
                    <Td bgColor="#83f28f">{"<0.02"}</Td>
                    <Td bgColor="#83f28f">{"<0.02"}</Td>
                    <Td bgColor="#83f28f">{"<0.02"}</Td>
                    <Td bgColor="#83f28f">{"<0.02"}</Td>
                    <Td bgColor="#83f28f">{"<0.02"}</Td>
                    <Td bgColor="#83f28f">{"<0.02"}</Td>
                  </Tr>
                  <Tr>
                    <Td>{i + 1}</Td>
                    <Td>{sample.value}</Td>
                    <Td bgColor={sample.methylamphetamine.color}>
                      {sample.methylamphetamine.value}
                    </Td>
                    <Td bgColor={sample.amphetamine.color}>
                      {sample.amphetamine.value}
                    </Td>
                    <Td bgColor={sample.ephedrine.color}>
                      {sample.ephedrine.value}
                    </Td>
                    <Td bgColor={sample.pseudoephedrine.color}>
                      {sample.pseudoephedrine.value}
                    </Td>
                    <Td bgColor={sample.mdma.color}>{sample.mdma.value}</Td>
                    <Td bgColor={sample.fentanyl.color}>
                      {sample.fentanyl.value}
                    </Td>
                    <Td bgColor={sample.cocaine.color}>
                      {sample.cocaine.value}
                    </Td>
                  </Tr>
                </>
              );
            } else {
              return (
                <Tr>
                  <Td>{i + 1}</Td>
                  <Td>{sample.value}</Td>
                  <Td bgColor={sample.methylamphetamine.color}>
                    {sample.methylamphetamine.value}
                  </Td>
                  <Td bgColor={sample.amphetamine.color}>
                    {sample.amphetamine.value}
                  </Td>
                  <Td bgColor={sample.ephedrine.color}>
                    {sample.ephedrine.value}
                  </Td>
                  <Td bgColor={sample.pseudoephedrine.color}>
                    {sample.pseudoephedrine.value}
                  </Td>
                  <Td bgColor={sample.mdma.color}>{sample.mdma.value}</Td>
                  <Td bgColor={sample.fentanyl.color}>
                    {sample.fentanyl.value}
                  </Td>
                  <Td bgColor={sample.cocaine.color}>{sample.cocaine.value}</Td>
                </Tr>
              );
            }
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
