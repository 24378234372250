import React from "react";
import { useFormik } from "formik";
import { newCOCStep1Schema } from "../../utils/schemas";
import { Button, HStack, SimpleGrid, Stack } from "@chakra-ui/react";
import { CInput } from "../common";

export default function NewCOCStep1({ onNextStep, formData }) {
  const formik = useFormik({
    initialValues: formData,
    validationSchema: newCOCStep1Schema,
    onSubmit: (values) => onNextStep(values),
  });

  const phoneNumberAutoFormat = (phoneNumber) => {
    const input = phoneNumber.replace(/\D/g, ""); // Remove non-digits
    return input.substring(0, 11);
  };

  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={3} mb="4">
        <CInput
          label="Company Name"
          name="clientName"
          placeholder="Enter Name"
          value={formik.values.clientName}
          onChange={formik.handleChange}
          errors={formik.errors.clientName}
          touched={formik.touched.clientName}
        />
        <CInput
          label="Contact Name"
          name="contactName"
          placeholder="Enter Contact Name"
          value={formik.values.contactName}
          onChange={formik.handleChange}
          errors={formik.errors.contactName}
          touched={formik.touched.contactName}
        />
        <CInput
          label="Client Email"
          name="email"
          placeholder="Enter Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          errors={formik.errors.email}
          touched={formik.touched.email}
        />
        <Stack>
          <CInput
            label="Phone"
            name="phone"
            placeholder="Enter Phone (xxxxxxxxxxx)"
            value={formik.values.phone}
            onChange={(e) => {
              let temp = phoneNumberAutoFormat(e.target.value);
              formik.setFieldValue("phone", temp);
            }}
            errors={formik.errors.phone}
            touched={formik.touched.phone}
          />
        </Stack>
      </SimpleGrid>
      <HStack justifyContent="end">
        <Button
          type="submit"
          onClick={formik.handleSubmit}
          isLoading={formik.isSubmitting}
          loadingText="Loading..."
          bgColor="black"
          borderRadius="lg"
          fontWeight="medium"
          py="6"
          px="7"
        >
          Next
        </Button>
      </HStack>
    </>
  );
}
