import React, { createContext, useState, useContext } from 'react';

// Create a new context object
const AuthContext = createContext();

// Create a custom hook to use the AuthContext
export const useAuth = () => {
    return useContext(AuthContext);
};

// Create the AuthProvider component
export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Example state for authentication status

    // Example function to toggle authentication status
    const toggleLogin = (value) => {
        setIsLoggedIn(value);
    };

    // Context value object
    const value = {
        isLoggedIn,
        toggleLogin,
    };

    // Provide the context value to the entire app
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
