import axios, { BASE_URL } from "../axios";

export default class FileModel {
  static endPoint = "upload/";

  static upload(body) {
    return axios.post(BASE_URL + this.endPoint, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}
