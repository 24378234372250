import React, { useEffect, useState } from "react";
import { ProtectedLayout } from "../layouts";
import { AuthModel, CocModel } from "../services/models";
import { useParams, useSearchParams } from "react-router-dom";
import { HStack, Spinner, Text, useToast } from "@chakra-ui/react";
import {
  ReportData,
  ReportFooter,
  SampleTable,
  SampleTableEditable,
} from "../components";
import { ASSET_URL } from "../services/axios";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

export default function Report() {
  const params = useParams();
  const user = AuthModel.getUser();
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [identifierVal, setIdentifierVal] = useState("");
  const toast = useToast();

  const onChangeSampleCell = (index, key, val, type) => {
    let newData = { ...report };
    let newObj = { ...report.samples[index] };
    if (type === "value") {
      newObj[key].value = val;
    } else {
      newObj[key].color = val;
    }
    newData.samples[index] = { ...newObj };
    setReport(newData);
  };

  const onUpdateTable = async () => {
    setIsSubmitting(true);
    try {
      if (identifierVal && identifierVal !== "") {
        report.identifierName = identifierVal;
      }
      let response = await CocModel.update(report._id, report);
      if (response.status === 200) {
        toast({
          description: "Report update successfully!",
          status: "success",
          isClosable: true,
        });
      }
    } catch (error) {
      toast({ description: "Something went wrong!" });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchReport = async () => {
      try {
        let response = await CocModel.getById(params.orderId);
        if (response.data.data.userId === user._id || user.role === "admin") {
          setReport(response.data.data);
          setIdentifierVal(response.data.data.identifierName);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchReport();
  }, []);

  return (
    <ProtectedLayout>
      {loading ? (
        <HStack justifyContent="center" alignItems="center" height="80px">
          <Spinner />
        </HStack>
      ) : (
        <>
          {report && report.reportType === "pdf" ? (
            <Document file={ASSET_URL + report.pdfFile}>
              <Page pageNumber={1} />
            </Document>
          ) : report ? (
            <>
              <ReportData report={report} />
              {user.role === "admin" ? (
                <SampleTableEditable
                  report={report}
                  onChangeSampleCell={onChangeSampleCell}
                  onUpdateTable={onUpdateTable}
                  isSubmitting={isSubmitting}
                  identifierVal={identifierVal}
                  setIdentifierVal={setIdentifierVal}
                />
              ) : (
                <SampleTable report={report} />
              )}
              <ReportFooter />
            </>
          ) : (
            <HStack justifyContent="center" alignItems="center" height="50px">
              <Text>Oops...Report Not Found!</Text>
            </HStack>
          )}
        </>
      )}
    </ProtectedLayout>
  );
}
