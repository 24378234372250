import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup.string().email("Invalid email!").required("Email required!"),
  password: yup
    .string()
    .min(8, "Min 8 characters required!")
    .required("Password required!"),
});

export const signupSchema = yup.object().shape({
  firstName: yup.string().required("First Name required!"),
  lastName: yup.string().required("Last Name required!"),
  email: yup.string().email("Invalid email!").required("Email required!"),
  password: yup
    .string()
    .min(8, "Min 8 characters required!")
    .required("Password required!"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match!")
    .required("Confirm Password required!"),
});

export const emailSchema = yup.object().shape({
  email: yup.string().email().required("Email is required!"),
});

export const businessInfoStep1Schema = yup.object().shape({
  businessName: yup.string().required("Name required!"),
  businessPhone: yup.string().required("Phone required!"),
  businessAddress: yup.string().required("Address required!"),
  monthlyReports: yup.number().required("Monthly Estimation required!"),
});

export const businessInfoStep2Schema = yup.object().shape({
  paymentType: yup.string().required("Payment selection required!"),
});

export const newCOCStep1Schema = yup.object().shape({
  clientName: yup.string(),
  contactName: yup.string().required("Contact Name required!"),
  phone: yup
    .string()
    .min(10, "Min 10 digits allowed!")
    .max(11, "Max 11 digits allowed!"),
  email: yup.string().email("Invalid email!").required("Email required!"),
});

export const newCOCStep2Schema = yup.object().shape({
  samplingFor: yup.string().required("Sampling For required!"),
  dateSampled: yup.string().required("Date Sampled required!"),
  samplingTechnique: yup.string().required("Sampling Technique required!"),
  securitySealNo: yup.string(),
  propertyType: yup.string().required("Property Type required!"),
  address: yup.string().when("propertyType", {
    is: (val) => val !== "vehicle",
    then: () => yup.string().required("Address required!"),
    otherwise: () => yup.string().notRequired(),
  }),
  city: yup.string().when("propertyType", {
    is: (val) => val !== "vehicle",
    then: () => yup.string().required("City required!"),
    otherwise: () => yup.string().notRequired(),
  }),
  state: yup.string().when("propertyType", {
    is: (val) => val !== "vehicle",
    then: () => yup.string().required("State required!"),
    otherwise: () => yup.string().notRequired(),
  }),
  postcode: yup.string().when("propertyType", {
    is: (val) => val !== "vehicle",
    then: () => yup.string().required("Postcode required!"),
    otherwise: () => yup.string().notRequired(),
  }),
  rego: yup.string().when("propertyType", {
    is: (val) => val === "vehicle",
    then: () =>
      yup
        .string()
        .min(5, "Min 5 characters")
        .max(7, "Max 7 characters")
        .required("REGO required!"),
    otherwise: () => yup.string().notRequired(),
  }),
  regoState: yup.string().when("propertyType", {
    is: (val) => val === "vehicle",
    then: () => yup.string().required("State required!"),
    otherwise: () => yup.string().notRequired(),
  }),
  VIN: yup.string().when("propertyType", {
    is: (val) => val === "vehicle",
    then: () =>
      yup.string().length(17, "Only 17 characters").required("VIN required!"),
    otherwise: () => yup.string().notRequired(),
  }),
});

export const questionnaireSchema = yup.object().shape({
  questions: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          answer: yup.boolean().required("Answer required!"),
          comment: yup.string(),
        })
        .test(
          "is-comment-required",
          "${path} comment invalid",
          function (item) {
            const index = parseInt(this.path.split("[")[1].split("]")[0], 10);
            return index < 5 && !item.answer && !item.comment
              ? this.createError({
                  path: `${this.path}.comment`,
                  message: "Comment required!",
                })
              : index === 5 && item.answer && !item.comment
              ? this.createError({
                  path: `${this.path}.comment`,
                  message: "Comment required!",
                })
              : true;
          }
        )
    )
    .required(),
});

export const reportTypeSchema = yup.object().shape({
  pdfFile: yup.mixed().required("PDF required!"),
  excelFile: yup.mixed(),
});

export const changePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, "Min 8 characters required!")
    .required("Password required!"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match!")
    .required("Confirm Password required!"),
});

export const ChangeProfileDataSchema = yup.object().shape({
  firstName: yup.string().required("First Name required!"),
  lastName: yup.string().required("Last Name required!"),
});

export const cocDeleteRequestSchema = yup.object().shape({
  coc: yup.string().required("COC required!"),
  status: yup.string().required("Status required!"),
  comment: yup.string().required("Comment required!"),
});

export const configSchema = yup.object().shape({
  username: yup.string().required("Username required!"),
  password: yup.string().required("Password required!"),
});

export const detailedAnalysisOrderSchema = yup.object().shape({
  DAFile: yup.mixed().required("PDF required!"),
});
