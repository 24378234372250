import { Box, Spinner, Text } from '@chakra-ui/react'
import React from 'react'

export default function Loader() {
    return (
        <Box position={'absolute'} top={0} bottom={0} left={0} right={0} height={'100vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Box>
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                />
                <Text>Loading...</Text>
            </Box>
        </Box>
    )
}
