import axios, { BASE_URL } from "../axios";

export default class AuthModel {
  static endPoint = "auth/";

  static login(body) {
    return axios.post(BASE_URL + this.endPoint + "login", body);
  }
  static signup(body) {
    return axios.post(BASE_URL + this.endPoint + "signup", body);
  }
  static setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }
  static getUser() {
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
    }
    return user;
  }
  static storeToken(token) {
    localStorage.setItem("token", token);
  }
  static logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }
  static getToken() {
    return localStorage.getItem("token");
  }
  static verifyToken() {
    return axios.get(BASE_URL + this.endPoint + "verify-token");
  }
  static getEmail(body) {
    return axios.post(BASE_URL + this.endPoint + "reset-password", body);
  }
  static verifyOTP(body) {
    return axios.post(BASE_URL + this.endPoint + "verify-otp", body);
  }
  static createNewPassword(body) {
    return axios.post(BASE_URL + this.endPoint + "create-password", body);
  }
}
